import React, { useState, useEffect } from "react";
import "./components/KitGrid.css";
import { FormButton, showMagniAlert } from "./components/formButton";
import { useNavigate, useParams } from "react-router-dom";
import LearningKitsModel from "./utils/LearningKitsModel";
import { ALLOWED_REFERRAL_CODES, getSetKitsGlobal, GRADE, SAVE_ITEM_KEY } from "./constants";
import _ from 'lodash'
import { FormInput } from "./components/formInput";
import { getKitInvoiceHtmlTemplate } from "./emailTemplates/kitInvoiceTemplate";
import PDFDocModel from "./pdfDocModel";
import alertify from "alertifyjs";
import { ScreenContainer } from "./App";
import { FeaturesList } from "./Home";
import { validateEmail } from "./NewCurriculum";
import { FormSelect } from "./components/formSelect";
import { LoadingSkeleton } from "./components/LoadingSkeleton";
import { KitCheckoutForm } from "./KitCheckout";


export const LearningKitDetails = () => {
  
  const navigate = useNavigate()
const [isKitLoading, setIsKitLoading] = React.useState(true);
const [loading, setLoading] = React.useState('')
  const [studentName, setStudentName] = React.useState('')
  const [applicationNumber, setApplicationNumber] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [streetAddress, setStreetAddress] = React.useState('')
  const [apt, setApt] = React.useState('')
  const [city, setCity] = React.useState('')
  const [state, setState] = React.useState('AZ')
  const [zip, setZip] = React.useState('')
  const [grade, setGrade] = React.useState('')

  const [isCheckoutMode, setIsCheckoutMode] = React.useState(false);
  const [kit, setKit] = useState(null);
  const [isIpad, setIsIpad] = React.useState(false);
  const [isAirPods, setIsEarPods] = React.useState(false);
  const [isMacbook, setIsMacbook] = React.useState(false);
  const [isJunior, setIsJunior] = useState(false);
  const [isMsLaptop, setIsMsLaptop] = useState(false);
  const [referralCode, setReferralCode] = React.useState('')

  const [showShippingInfo, setShowShippingInfo] = React.useState(false);


  const [addons, setAddons] = useState({
    replaceMacbookWithIpad: false,
    addAppleEarpods: false,
  });

  const {id} = useParams();


  const initialize = async () => {
    if(id) {
      setIsKitLoading(true)
      if(!getSetKitsGlobal()?.length) {
      const f = await LearningKitsModel.getLearningKitsFromDb()
          getSetKitsGlobal(f)
      }
      const isJ = true// GRADE.findIndex(f =>f === grade) < 7
      let selectedKit = LearningKitsModel.getLearningKits(false, false, false, isJ)?.find(k => k?.id === id)

      || LearningKitsModel.getSpecialNeedsKits(false, false, false, isJ)?.find(k => k?.id === id)

      || getSetKitsGlobal()?.find(k => k?.id === id)

      if(selectedKit?.id &&  !selectedKit?.details?.[0]?.id && !selectedKit?.id?.includes('INTERNAL')) {
        const details = await LearningKitsModel.getDbKitDetails(false, false, false,  selectedKit?.id, selectedKit)
        selectedKit.details = details;
      }

      if (selectedKit?.id) {
        setKit(selectedKit);
        setIsJunior(isJ);
      }
      setIsKitLoading(false)
    }
  }

  useEffect(() => {
        initialize();
  }, [id]);


  React.useEffect(() => {
    scrollToTop();

  }, [isCheckoutMode])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Add smooth scrolling
    });
  };



  React.useEffect(() => {

    if(kit?.id) {
      let k = {...kit}
      let updatedKit = LearningKitsModel.updateKitPriceWithAddOns(kit?.details?.elements, isIpad, isMacbook, isAirPods,isMsLaptop, k)
      setKit(updatedKit)
    }

  }, [isIpad, isMacbook, isAirPods, isMsLaptop])




if(isKitLoading) {
  return (<div>
     <ScreenContainer title="loading Kit...">
    <LoadingSkeleton />
    </ScreenContainer>
  </div>)
}


if (!kit) {
  return (
    <div className="text-center py-20">
      <h2 className="text-2xl font-semibold text-gray-600">
        No kit selected. Please select a kit to view details.
      </h2>
    </div>
  );
}


  const renderCheckoutForm = () => {
    const materials = kit?.details?.elements?.map?.(e => e?.title)?.join(', ')
    return <KitCheckoutForm kit={kit}  checkoutEnabled={isCheckoutMode} onBack={() => setIsCheckoutMode(false)}
    total={kit?.details?.total} materials={materials} _subject={kit?.subject || kit?.title} />

   
  }


  const renderTotalAndTax = () => {

    return (<div>
  <hr className="my-6 border-gray-300" />
  
  {/* Updated Pricing Section */}
  <div className="bg-gray-50 p-4 rounded-lg">
    <h4 className="text-right text-gray-700 mb-2">
      Kit Price:{" "}
      <span className="font-bold text-gray-800">${kit?.details?.price || ''}</span>
    </h4>
    <h4 className="text-right text-gray-700 mb-2">
      Tax: <span className="font-bold text-gray-800">${kit?.details?.tax || ''}</span>
    </h4>
    <h4 className="text-right text-gray-700 mb-2">
      Shipping:{" "}
      <span className="font-bold text-gray-800">
        ${kit?.details?.shipping || ''}
      </span>
    </h4>
    <hr className="my-4 border-gray-400" />
    <h4 className="text-right text-xl font-bold text-purple-600">
      Total: ${kit?.details?.total}
    </h4>
  </div>

    </div>)
  }

  const renderBoxContents = () => {
    return (
      <div className="relative w-full mx-auto overflow-hidden shadow-xl transition ">
        <div
          className="absolute inset-0 bg-cover bg-center shadow-xl bg-[#FAF3E0]"
          style={{
           //backgroundImage: `url(${require('./assets/product-images/box.jpg')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            borderRadius: 5,
            
          }}
        ></div>
        <div
  className={`grid ${kit.details.elements.length === 1 ? 'place-items-center' : 'grid-cols-2'} gap-2 p-1`}
>
  {kit.details.elements.map((el, index) => (
    <div
      onMouseEnter={() =>
        document.getElementById(`TXT_${index}`).classList.add('scale-105')
      }
      onMouseLeave={() =>
        document.getElementById(`TXT_${index}`).classList.remove('scale-105')
      }
      id={`IMG_${index}`}
      key={index}
      className="flex flex-col items-center justify-center rounded-lg  shadow-md transition-transform transform hover:scale-105"
      style={{
        borderRadius: 5,
        padding: '10px',
        marginBottom: '15px',
        
      }}
    >
      <div className=" h-40 rounded-md flex items-center justify-center overflow-hidden "
      style={kit.details.elements.length ? {height: '100%'} : null}
      >
        <img
          src={el?.variations?.length ? el?.variations?.[el?.selectedVariationIndex || 0]?.image : el.imageUrl}
          alt=""
          className="h-full rounded-[15px]" // Adjusted to show full image without cropping
        />
      </div>
    </div>
  ))}
</div>

      </div>
    );
  };

  const updateSelectedVariation = async (productIndex, variationIndex) => {
    // ✅ Update the kit state to reflect the selected variation
    setKit((prevKit) => {
      const newKit = { ...prevKit };
      newKit.details.elements = newKit.details.elements.map((el, index) =>
        index === productIndex ? { ...el, selectedVariationIndex: variationIndex } : el
      );
      return newKit;
    });
  
    // ✅ Wait for the state update to be applied before fetching new pricing
    setTimeout(async () => {
      setKit((prevKit) => {
        // ✅ Update pricing details based on the new variation
        return LearningKitsModel.updateKitPriceWithAddOns(
          prevKit.details.elements,
          isIpad,
          isMacbook,
          isAirPods,
          isMsLaptop,
          prevKit
        );
      });
    }, 100);
  };
  
  
  


  const renderKitDetails = () => {
    return (

      <ScreenContainer title={`What's Included?`}
      key={`${isAirPods}-${isIpad}-${isMacbook}`}

      onBack={() => navigate(-1)}
      >
  
          <div className="p-6">
            <div className=" fw-bold  text-gray-700  px-5 sm:text-[20px] md:text-[35px] "
            
            >
              {kit?.title} Kit
              
            </div>
            {/* <small className="mx-5 fs-4 text-indigo-500">{_.capitalize(_.lowerCase(grade))} grade</small> */}
            <div
              id="photoCollage"
              className="grid md:grid-cols-2 "
            >
              <div className="md:grid-cols-2 overflow-visible mt-5"
              
style={{  borderRadius: 30}}>

      {renderBoxContents()}


</div>

<div className="md:col-6 mt-4">

      <div className="relative">
        <div className=" p-4 ">
          
             <ul className="list-disc pl-5 space-y-1">
             {
  kit?.details?.elements?.map((el, index) => (
    <div key={index}>
      <div
        className=" align-items-center hover:scale-105 transition-all ease-in-out mb-4 position-relative"
        id={`TXT_${index}`}
        onMouseEnter={() =>
          document.getElementById(`IMG_${index}`).classList.add('scale-105')
        }
        onMouseLeave={() =>
          document.getElementById(`IMG_${index}`).classList.remove('scale-105')
        }
      >
        <div
          className="position-absolute rounded-pill bg-indigo-500"
          style={{
            width: '8px',
            height: '100%',
            left: '-16px',
          }}
        ></div>
        <span className="fw-bold">{el?.title}</span>
        <div className="mt-2">
        {
        
  typeof el.extraInfo === 'string' && el.extraInfo?.length ? (
    <span
      dangerouslySetInnerHTML={{
        __html: el.extraInfo.replace(
          /(https?:\/\/[^\s]+)/g,
          `<a href="$1" target="_blank" class='text-indigo-500 underline' rel="noopener noreferrer">$1</a>`
        ),
      }}
    />
  ) : 
  el?.variations?.[el?.selectedVariationIndex || 0]?.extraInfo?.length ?  <span
  dangerouslySetInnerHTML={{
    __html: el?.variations?.[el?.selectedVariationIndex || 0]?.extraInfo.replace(
      /(https?:\/\/[^\s]+)/g,
      `<a href="$1" target="_blank" class='text-indigo-500 underline' rel="noopener noreferrer">$1</a>`
    ),
  }}
/>
:
  (
    el.extraInfo
  )
}
{el.variations?.length ? <div className="flex flex-col md:flex-row mb-10 mt-3">
      {
       el.variations?.length &&
       el.variations?.map((variation, vIndex) => {
        const selected = el.selectedVariationIndex === vIndex  || (! el.selectedVariationIndex && vIndex === 0)
        return (
         <div
           key={vIndex}
           className={`${selected ? 'border-5' :  'border-1'} p-2 cursor-pointer rounded m-1`}
           style={selected? { borderColor: 'green', background: '#eeeeee' } : {}}
           onClick={() => updateSelectedVariation(index, vIndex)}
         >
           {variation?.image && <img src={variation.image} className="w-[100px]" />}
           <div className="mt-2">{variation.title}</div>
         </div>
       )})
       
      }
     </div> : null}
</div>

      </div>


    </div>
  ))
}


                    </ul>
         
        </div>
      </div>

</div>


            </div>
  
            <hr className="my-6 border-gray-300" />
  
            {/* Add Ons & Savings Section */}
            
             <div className="bg-gray-50 p-4 rounded-lg mb-6">
              <h3 className="text-lg font-bold text-gray-700">
                Add Ons & Savings
              </h3>

              <small className="text-success">We discount Apple products when added to learning kits which makes them cheaper than anywhere else on the marketplace</small>
             <div className=" mb-4"></div>
              <div className="flex items-center justify-between mb-3">
                <label className="text-gray-600">
                  Add iPad 10th Gen
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isIpad}
                  onChange={async () => {
                    setIsIpad(!isIpad)
                  }}
                />
              </div>

              <div className="flex items-center justify-between mb-3">
                <label className="text-gray-600">
                  Add MacBook Air
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isMacbook}
                  onChange={() => {
                    setIsMacbook(!isMacbook)
                  }}
                />
              </div>
  
              <div className="flex items-center justify-between">
                <label className="text-gray-600">
                  Add Apple EarPods
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isAirPods}
                  onChange={() => {
                    setIsEarPods(!isAirPods)
                  }}
                />

                
              </div>

              <div className="flex items-center justify-between mt-3">
                <label className="text-gray-600">
                  Add Microsoft Surface Laptop
                </label>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 text-purple-600"
                  checked={isMsLaptop}
                  onChange={() => {
                    setIsMsLaptop(!isMsLaptop)
                  }}
                />

                
              </div>
              
            </div>
          {renderTotalAndTax()}
  
              <hr/>
            
              <div>
  
              </div>
  
            <div className="bg-gray-50 p-4 rounded-lg d-flex gap-2">
             
            <FormButton label="Checkout" isPrimary isFullWidth
            onClick={() => setIsCheckoutMode(true)}
            />

           
  
            </div>
            <div className="text-center m-10 text-success">
              Invoice & curriculum are provided for free after checkout
            </div>
          </div>
          </ScreenContainer>
    );
  }

  if(isCheckoutMode) {
    return renderCheckoutForm()
  }

  return renderKitDetails()


};

const formatDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${month}-${day}-${year}`;
};


export const TotalTaxShippingRenderer = ({subtotal = 0, tax = 0, total = '', shipping = 0}) => {

  return (<div>
<hr className="my-6 border-gray-300" />

{/* Updated Pricing Section */}
<div className="bg-gray-50 p-4 rounded-lg">
  <h4 className="text-right text-gray-700 mb-2">
    Subtotal:{" "}
    <span className="font-bold text-gray-800">${(subtotal || '')}</span>
  </h4>
  <h4 className="text-right text-gray-700 mb-2">
    Tax: <span className="font-bold text-gray-800">${(tax || '')}</span>
  </h4>
  <h4 className="text-right text-gray-700 mb-2">
    Shipping:{" "}
    <span className="font-bold text-gray-800">
      ${(shipping || '')}
    </span>
  </h4>
  <hr className="my-4 border-gray-400" />
  <h4 className="text-right text-xl font-bold text-purple-600">
    Total: ${(total || '')}
  </h4>
</div>

  </div>)
}