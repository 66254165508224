import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ScreenContainer } from "../App";
import { FormButton } from "../components/formButton";
import { ModalWrapper } from "./ManagerOrders";
import alertify from "alertifyjs";

export const CreateProduct = () => {
    const [loading, setLoading] = useState(false);
    const { kitId, kitName } = useParams();
    const [products, setProducts] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [showAdd, setShowAdd] = useState(false);
    const isDev = false;
    const URL_PREFIX = isDev 
        ? "http://127.0.0.1:5001/newlessonplan-prod/us-central1" 
        : 'https://us-central1-newlessonplan-prod.cloudfunctions.net';

    const [productForm, setProductForm] = useState({
        URL: "",
        title: "",
        description: "",
        price: 20,
        tax: 4,
        imageUrl: "",
        isJunior: false,
        isBoth: false,
        kitId,
        isSpecialNeeds: false,
        extraInfo: '',
        variations: [] // ✅ Variations support
    });

    useEffect(() => {
        fetchProducts();
    }, [kitId]);

    const fetchProducts = async () => {
        try {
            setLoading(true);
            const response = await fetch(`${URL_PREFIX}/handleProducts?kitId=${kitId}`);
            let data = await response.json();

            // ✅ Ensure every product has a variations field
            data = data.map(product => ({
                ...product,
                variations: product.variations || []
            }));

            setProducts(data);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setProductForm({
            ...productForm,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleImageUpload = async (e, variationIndex = null) => {
        setLoading(true);
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = async () => {
            const base64Data = reader.result.split(",")[1];
            const mimeType = file.type;

            try {
                const response = await fetch(`${URL_PREFIX}/uploadImage`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ imageBase64: base64Data, mimeType }),
                });

                if (!response.ok) {
                    throw new Error(`Image upload failed: ${response.statusText}`);
                }

                const { imageUrl } = await response.json();
                
                if (variationIndex === null) {
                    setProductForm({ ...productForm, imageUrl });
                } else {
                    const updatedVariations = [...productForm.variations];
                    updatedVariations[variationIndex].image = imageUrl;
                    setProductForm({ ...productForm, variations: updatedVariations });
                }
                
            } catch (error) {
                console.error("Image upload error:", error);
            } finally {
                setLoading(false);
            }
        };

        reader.readAsDataURL(file);
    };

    const handleSubmit = async () => {
      if (
        !productForm?.title?.length || 
        !productForm?.price || 
        !productForm?.imageUrl || 
        (productForm.variations?.length && productForm.variations.some(variation => 
          !variation.price || !variation.image || !variation.title || !variation?.url
        ))
      ) {
        // ❌ Invalid submission, handle error here
        alert('MISSING INFORMATION.' + " Check product title, price and image. If you have variations, check that each variation has price, title, URL and image")
        return;
      }
      
        try {
            const method = editingProductId ? "PUT" : "POST";
            const url = editingProductId
                ? `${URL_PREFIX}/handleProducts?id=${editingProductId}`
                : `${URL_PREFIX}/handleProducts`;

            await fetch(url, {
                method,
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    ...productForm,
                    variations: productForm.variations || [] // ✅ Ensure variations are always included
                }),
            });

            resetForm();
            fetchProducts();
            setShowAdd(false);
        } catch (error) {
            console.error("Submission error:", error);
        }
    };

    const resetForm = () => {
        setProductForm({
            URL: "",
            title: "",
            description: "",
            price: 10,
            tax: 4,
            imageUrl: "",
            isJunior: false,
            isBoth: false,
            kitId,
            isSpecialNeeds: false,
            extraInfo: '',
            variations: []
        });
        setEditingProductId(null);
    };

      const cl = "rounded p-2 w-100 m-2 border-1 mb-10"
    const handleDelete = async (productId) => {
        try {
            await fetch(`${URL_PREFIX}/handleProducts?id=${productId}`, { method: "DELETE" });
            fetchProducts();
        } catch (error) {
            console.error("Delete error:", error);
        }
    };

    const handleEdit = (product) => {
        setShowAdd(true);
        setProductForm(product);
        setEditingProductId(product.id);
    };


    React.useEffect(() => {
     if(!showAdd) {
      resetForm()
     }
    }, [showAdd])

    const handleAddVariation = () => {
        setProductForm({
            ...productForm,
            variations: [...productForm.variations, { image: "", price: 0, customField: "" }]
        });
    };

    const handleRemoveVariation = (index) => {
        const updatedVariations = [...productForm.variations];
        updatedVariations.splice(index, 1);
        setProductForm({ ...productForm, variations: updatedVariations });
    };

    const handleVariationChange = (index, field, value) => {
        const updatedVariations = [...productForm.variations];
        updatedVariations[index][field] = value;
        setProductForm({ ...productForm, variations: updatedVariations });
    };

    return (
      <div >
        <ScreenContainer title={`Products in -> ${kitName}`}>
         
                {loading && <div className="text-success border-2 text-center m-10 p-2 fs-3">Loading....</div>}

                <div className="m-10 flex justify-center">
                    <FormButton label="+ Add New Product" isPrimary onClick={() => setShowAdd(true)} />
                </div>

                {showAdd && (

                    <ModalWrapper>
                      <div className="bg-white overflow-scroll " style={{minHeight: '60vh', maxHeight: '70vh'}}>
                        <ScreenContainer title="Add/Edit Product" onBack={() => setShowAdd(false)}>

                        
                        <div className="text-center px-2">
                        <FormButton
                                superExtraClass=" mt-10 "
                                isPrimary isFullWidth label={'SAVE'} onClick={handleSubmit} />
                        </div>

                            <div className="p-10">
                              Title
                            <input
        className={cl}
          type="text"
          name="title"
          value={productForm.title}
          placeholder="Title (ESA WILL SEE THIS)"
          onChange={handleInputChange}
        />
  Amazon URL
        <input
        className={cl}
          type="text"
          name="URL"
          value={productForm.URL}
          placeholder="Amazon URL"
          onChange={handleInputChange}
        />
                                
         Extra Info/Description
          <textarea
        className={cl + ' text-success'}
          name="extraInfo"
          value={productForm.extraInfo}
          placeholder="EXTRA INFO like link or more specs (VISIBLE ONLY TO CUSTOMERS, NOT ESA)"
          onChange={handleInputChange}
        ></textarea>
        Price
        <input
        className={cl}
          type="number"
          name="price"
          value={productForm.price}
          placeholder="Price"
          onChange={handleInputChange}
        />
       
          <img src={productForm.imageUrl} className="w-[200px] rounded m-2" />
        <input
        className={cl}
          type="file"
          onChange={handleImageUpload}
        />
      

                                <FormButton
                                isFullWidth superExtraClass={' bg-black'}
                                label="+ Add Variation" isPrimary onClick={handleAddVariation} />
                                
                                {productForm.variations.map((variation, index) => (
                                    <div key={index} className="border-2 border-success p-4 rounded my-2">
                                     <div className="fs-2 fw-bold"> Variation {index + 1} <br/></div>
                                      Title
                                       <input className="rounded p-2 w-100 m-2 border-1"
                                            type="text" placeholder="Variation Title (e.g. Green)"
                                            value={variation.title} onChange={(e) => handleVariationChange(index, "title", e.target.value)} />

                                            Variation product URL 
                                       <input className="rounded p-2 w-100 m-2 border-1"
                                            type="text" placeholder="(e.g. the link to green airPods)"
                                            value={variation.url} onChange={(e) => handleVariationChange(index, "url", e.target.value)} />
                                             Extra Info/Description
                                                <textarea
                                                className={cl + ' text-success'}
                                                name="extraInfo"
                                                value={variation.extraInfo}
                                                placeholder="VARIATION EXTRA INFO like link or more specs (VISIBLE ONLY TO CUSTOMERS, NOT ESA)"
                                                onChange={(e) => handleVariationChange(index, "extraInfo", e.target.value)}
                                                ></textarea>


                                        Price
                                        <input className="rounded p-2 w-100 m-2 border-1"
                                            type="number" placeholder="Variation Price"
                                            value={variation.price} onChange={(e) => handleVariationChange(index, "price", e.target.value)} />

                                        {/* <input className="rounded p-2 w-100 m-2 border-1"
                                            type="text" placeholder="Custom Field"
                                            value={variation.customField} onChange={(e) => handleVariationChange(index, "customField", e.target.value)} /> */}
                                        <div className="mt-2">
                                          Image
                                          </div>
                                        <input type="file" onChange={(e) => handleImageUpload(e, index)} />
                                        {variation.image && <img src={variation.image} className="w-[100px] h-[100px] m-2 rounded" />}
                                        
                                        <FormButton label="Remove"  onClick={() => handleRemoveVariation(index)} superExtraClass=" mt-10 bg-danger" />
                                    </div>
                                ))}
                                <div className="h-[3px] w-100 bg-black mt-10" />

                              
                            </div>
                        </ScreenContainer>
                        </div>
                    </ModalWrapper>
                )}

{products.map((product) => (
          <li key={product.id} className="w-100 border-5 p-2">
             <div className="d-flex underline cursor-pointer m-10 mb-10"
           // onClick={() => window.location.href ='/katy-products/' + kit.id + '/' + kit?.title}
            >
            <img src={product.imageUrl} alt={product.title}  className="w-[160px] h-[160px] bg-black rounded" />
            <div className="p-2 w-100">
            <h3 className="fs-2 fw-bold">{product.title}</h3>
            <p className="fw-bold">Price: ${product.price}</p>
            <p>Tax: ${product.tax}</p>
            <p>Description: {product.description}</p>
            <a href={product.URL} className="pr-40">URL: {product.URL?.substring(0, 40)}...</a>
            <p>isJunior: {JSON.stringify(product.isJunior) || 'false'}</p>
            <p>isBoth: {JSON.stringify(product.isBoth) || 'false'}</p>
            </div>
            </div>
            
            <div className="d-flex w-100 gap-3">
            <FormButton label="Edit" onClick={() => handleEdit(product)} isFullWidth/>
            <FormButton isDanger label="Delete" superExtraClass=" bg-danger" onClick={() => handleDelete(product.id)} isFullWidth />
            </div>
         
          </li>
        ))}
            
        </ScreenContainer>
        </div>
    );
};
