import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getVoucherTemplate } from '../emailTemplates/voucherTemplate';


export const   generateUniqueCoupon =  (maxUsage = 15)  => {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomString = Math.random().toString(36).substring(2, 10).toUpperCase(); // Random alphanumeric string
    let couponCode = '';
  
    // Sprinkle the timestamp across the random string
    for (let i = 0; i < randomString.length; i++) {
      couponCode += randomString[i] + (timestamp[i] || ''); // Interweave timestamp characters
    }
  
    return `NLP-${maxUsage}-${couponCode}`;
  }


  export const emailCoupon = async (toEmail = '', coupon = '') => {

    coupon = coupon?.replace(`"`, '')
     callEmailSvc('support@newlessonplan.com', toEmail, 'Your Voucher Code', getVoucherTemplate(coupon))
    return true;

  }

  export const adminEmailMeOrderDetails = async (htmlString = '', title = '') => {    
    try{
      callEmailSvc('support@newlessonplan.com', 'aj.tx1986@gmail.com', title, htmlString)
    }
    catch {}
   
  }

  export const callCouponSvc = async (coupon = '', isCreate = false, isCheckBalance = false) => {
    const url  = `https://us-central1-newlessonplan-prod.cloudfunctions.net/manageCouponV2`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ coupon, isCreate, isCheckBalance }),
    });

    const result = await response.json();

  
    if (response.ok) {
      const {message, isSuccess, usageCount} = result;
      return result;
    } else {
     return null;
    }
  }

  export const handleApplyCoupon = async (coupon = '') => {
    if (!coupon) {
      return false;
    }
      const result = await callCouponSvc(coupon)
      if(result?.isSuccess) {
        return result
      }
      return false;
  };
  
  

  export const CouponBanner = ({text = 'Click to Buy a Voucher to Save Big & Get Reimbursed Smoothly',
    onClick = () => { window.location.href = '/vouchers-purchase'}, isBanner = true, description = '', grad,

   }) => {
    const isSmallScreen = window.innerWidth < 750
    if((window.location?.href?.includes('vouchers-purchase') || window.location?.href?.includes('voucher-success')) && isBanner) {
        return null;
    }

    const gradients =!grad ? `from-[#FF5E8E] to-[#9089fc]` : grad
    return (
      <div className='mt-10'>
        <div
          className={` p-${isBanner ?  2 : 10} text-center align-items-center align-self-center bg-gradient-to-tr ${gradients}
           text-white cursor-pointer transition-all transform hover:scale-105 shadow-lg `}
          style={{
            margin: '0 auto',
            maxWidth: isSmallScreen ? '90%' :  '60%',
            borderRadius: '1rem',
            zIndex:999,
            borderRadius: 4,
          }}
          onClick={onClick}
        >
          {
            description ?  <div className={`fs-6  mt-6 bg-black  rounded text--white  p-1 px-4 absolute right-[-20px] top-[-35px]`}>
           💲 {description}
          </div> : null
          }


          <div className={`fs-${isBanner ?  3 : 1} fw-bold`}>
            {text}
          </div>

          
            {
            description ?  <div className="fs-4 fw-bold mt-6 border-2 p-3 rounded w-100 text-white"
            style={{maxWidth: '90%'}}
            >
            Purchase
          </div> : null
          }
         
        </div>
      </div>
    );
  };



  export const getVoucherDetails = (maxAllowed = 15) => {
    let link = ''
    let details = {name: '', description: '', price: '', link: '', maxAllowed: 15}
    if(maxAllowed === 15) {
 link =  global.isDev ?  'https://buy.stripe.com/test_dR62bP9cA5R16D6146' :
              'https://buy.stripe.com/cN2dRKf9gct7ad29AC'
              details.name = '15 Curriculums for $9.99'
              details.description = 'Save $5'
              details.link = link;
              details.maxAllowed = 15;
    }

    if(maxAllowed === 30) {
        link =  global.isDev ?  'https://buy.stripe.com/test_7sIdUx60ocfpf9CcMN' :
                     'https://buy.stripe.com/6oE8xqe5c78NclafZ1'

                     details.name = '30 Curriculums for $19.99'
                     details.description = 'Save $10'
                     details.link = link;
                     details.maxAllowed = 30;
    }

    if(maxAllowed === 8) {
      link =  global.isDev ?  'https://buy.stripe.com/test_14k3fT74s2EP0eIaEI' :
                   'https://buy.stripe.com/aEU9BuaT0dxbfxm004'

                   details.name = '8 Curriculums for $4.99'
                   details.description = 'Save $3'
                   details.link = link;
                   details.maxAllowed = 8;
  }

  if(maxAllowed === 5) {
    link =  global.isDev ?  'https://buy.stripe.com/test_6oE7w960o3ITaTmcMP' :
                 'https://buy.stripe.com/dR600U8KS0Kp4SI7sx'

                 details.name = '5 Curriculums for $2.99'
                 details.description = 'Save $2'
                 details.link = link;
                 details.maxAllowed = 5;
}


    // do 8 and 5

    return details;
     
  }

  export const getAvailableVouchers = () =>  [getVoucherDetails(5), getVoucherDetails(8), getVoucherDetails(15), getVoucherDetails(30)]


  export const callEmailSvc = async (from = '', to = '', subject = '', body = '') => {
try {

  const b = {
    body, 
    subject, 
    email: to,
    from
  }
  const url  = `https://us-central1-newlessonplan-prod.cloudfunctions.net/sendEmail`

  const response =  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(b),
  });
}
catch {
  return true;
}

 
    return true;
  }