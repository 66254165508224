import React from "react";
import _ from "lodash";

export default class LearningKitsModel {


  static getLearningKitsFromDb = async () => {
    const response = await fetch(`https://us-central1-newlessonplan-prod.cloudfunctions.net/handleKits`);
    const data = await response.json();
    let result = data || [];
    result = _.orderBy(result, 'createdAt', 'desc')?.filter(f=>f?.isPublished || window.location.href.includes('localhost'))
    result = _.orderBy(
      result, // Your array of objects
      [item => item?.createdAt || 0], // Sort by createdAt, fallback to 0 if undefined/null
      ['desc'] // Descending order
    );
    return result;
    return data;
  }
  static getKitProducts = async (kitId) => {
    try {
        
      const response = await fetch(`https://us-central1-newlessonplan-prod.cloudfunctions.net/handleProducts?kitId=${kitId}`);
      const data = await response.json();
      return data;
    } catch (error) {
    return null
    }
   
  };

  static generateReferralCode = async (body) => {

    try {
        const url = `https://us-central1-newlessonplan-prod.cloudfunctions.net/gRCodeV1`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body), // Send JSON body with base64 file
        });

        if (response.ok) {
        
          const r = await response.json();
          
          if(r?.isSuccess && r?.referralCode) {
            return r?.referralCode
          }
        } 
    } catch (error) {
      return null;
    }
  }
  static checkRCode = async (code) => {

    const body = {code: code}
    try {
        const url = `https://us-central1-newlessonplan-prod.cloudfunctions.net/checkRCode`;
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body), // Send JSON body with base64 file
        });

        if (response.ok) {
        
          const r = await response.json();
          
          if(r?.isSuccess ) {
            return true
          }
          return false;
        } 
    } catch (error) {
      return false;
    }
  }
  

  static PROFIT_MARGIN = 200; //shooting for $300



  static MS_SURFACE_LAPTOP = {
    id: 'MS_SURFACE_LAPTOP',
    title: `Microsoft Surface Laptop With Windows 11, 13.8" Touchscreen Display, Snapdragon X Plus (10 core), 16GB RAM, 512GB SSD Storage`,
    URL: `https://www.amazon.com/Microsoft-Surface-Windows-Touchscreen-Snapdragon/dp/B0CXKY7JYZ/ref=sxin_36_pa_sp_search_thematic_sspa?content-id=amzn1.sym.86e26e76-8138-47f9-9d58-a550bcf8f353%3Aamzn1.sym.86e26e76-8138-47f9-9d58-a550bcf8f353&crid=3163RAGAEP5UL&cv_ct_cx=Microsoft%2BSurface%2BLaptop&keywords=Microsoft%2BSurface%2BLaptop&pd_rd_i=B0CXL6B4QF&pd_rd_r=72aa8b89-5898-44e3-bd42-09ca4cbcec3f&pd_rd_w=UDXyf&pd_rd_wg=bf1CE&pf_rd_p=86e26e76-8138-47f9-9d58-a550bcf8f353&pf_rd_r=A6SBZPTVYQQHAQKQNXCP&qid=1733857474&sbo=RZvfv%2F%2FHxDF%2BO5021pAnSA%3D%3D&sprefix=microsoft%2Bsurface%2Blaptop%2Caps%2C304&sr=1-1-6024b2a3-78e4-4fed-8fed-e1613be3bcce-spons&sp_csd=d2lkZ2V0TmFtZT1zcF9zZWFyY2hfdGhlbWF0aWM&th=1`,
    price: 1100,
    tax: 120,
    imageUrl: require('../assets/product-images/MS_SURFACE_LAPTOP.png')
  }

  static MACBOOK = {
    id: "MACBOOK",
    title: `Apple MacBook Air - 15" Display,
8-Core CPU
10-Core GPU
16GB Memory
256GB SSD Storage`,
    description: "",
    price: 1200,
    tax: 105,
    imageUrl: require("../assets/product-images/MAC_BOOK.png"),
  };

  static IPAD = {
    id: "IPAD",
    title: "Apple iPad (10th Gen) - 10.9-inch screen, Wi‑Fi 64GB - Space Gray",
    description: "",
    price: 400,
    tax: 36,
    imageUrl: require("../assets/product-images/ipad.jpeg"),
  };

  static EARPODS = {
    id: "EARPODS",
    title: "Apple EarPods",
    description: "",
    price: 150,
    tax: 15,
    imageUrl: require("../assets/product-images/earpods.jpeg"),
  };

  static SPECTRUM_BOOK = {
    id: "BOOK",
    title: "Curriculum Book",
    description: "",
    price: 20,
    tax: 3,
    imageUrl: require("../assets/product-images/book.jpg"),
  };

  static CORE_SPECTRUM_BOOK = {
    id: "CORE_BOOK",
    title:
      "Language arts (reading & grammar), science, math, and social studies workbooks",
    description: "",
    price: 40,
    tax: 6,
    imageUrl: require("../assets/product-images/book.jpg"),
  };

  static STATIONARY = {
    id: "STATIONARY",
    title: "4 Pens, 2 highlighters, 4 notebooks",
    description: "",
    price: 15,
    tax: 4,
    imageUrl: "",
    imageUrl: require("../assets/product-images/stationary.jpg"),
  };

  static CURRICULUM = {
    id: "CURRICULUM",
    title: "ESA handbook-approved curriculum",
    description: "",
    price: 0,
    tax: 0,
    imageUrl: "",
    imageUrl: require("../assets/product-images/curriculum.jpg"),
  };

  static MATH_KIT_AGE_4_12 = {
    url: "https://a.co/d/9AqHxpG",
    title:
      "STEM KIT: Magnetic tiles, building blocks and Construction STEM Stacking blocks",
    description: "",
    price: 50,
    tax: 10,
    imageUrl: require("../assets/product-images/math_kit_4_12.png"),
    isJunior: true,
  };

  static MATH_KIT_AGE_13_plus = {
    url: "https://a.co/d/e4EAQ2v",
    title: "Board Game: Monopoly",
    description: "",
    price: 28,
    tax: 5,
    imageUrl: require("../assets/product-images/math_kit_13+.png"),
    isJunior: false,
  };

  static SOCIAL_STUDIES_KIT_AGE_4_12 = {
    url: "https://a.co/d/1cm9YwK",
    title: "World Map Puzzle With Country Flags & Capitals",
    description: "",
    price: 35,
    tax: 6,
    imageUrl: require("../assets/product-images/social_kit_4_12.png"),
    isJunior: true,
  };

  static SOCIAL_STUDIES_KIT_AGE_13_plus = {
    url: "https://a.co/d/1qeNL6A",
    title: "Geography Card Game",
    description: "",
    price: 26,
    tax: 5,
    imageUrl: require("../assets/product-images/social_kit_13+.png"),
    isJunior: false,
  };

  static LANGUAGE_ARTS_KIT_AGE_4_12 = {
    url: "https://a.co/d/dSj4CDF",
    title: "Board Game: Scrabble Junior",
    description: "",
    price: 25,
    tax: 5,
    imageUrl: require("../assets/product-images/language_arts_kit_4_12.png"),
    isJunior: true,
  };

  static LANGUAGE_ARTS_KIT_AGE_13_plus = {
    url: "https://a.co/d/39cNxDQ",
    title: "Board Game: Scrabble Word Game",
    description: "",
    price: 50,
    tax: 8,
    imageUrl: require("../assets/product-images/language_arts_kit_13+.png"),
    isJunior: false,
  };

  static SCIENCE_KIT_AGE_4_12 = {
    url: "https://a.co/d/arfcDxk",
    title: "3D Pen Set STEM kit - 3D Pen, Doodle Pad, Mixed Color Start Pack, 72 Strands of Plastic, USB-C Cable and Activity Guide",
    description: "",
    price: 55,
    tax: 8,
    imageUrl: require("../assets/product-images/science_kit_4_12.png"),
    isJunior: true,
  };

  static SCIENCE_KIT_AGE_13_plus = {
    url: "https://www.amazon.com/dp/B0B6BB4TVC?psc=1&ref_=cm_sw_r_apin_ct_ZN745G4W810FZKA4BNHP&language=en_US",
    title: "Indoor Garden Hydroponic Kit - LED Grow Light System, Power Adapter, Grow Sponges, and Lettuce Seeds",
    description: "",
    price: 75,
    tax: 12,
    imageUrl: require("../assets/product-images/science_kit_13+.png"),
    isJunior: false,
  };

  // SCIENCE EXTRA

  static SC_EXTRA = [
    {
      url: "https://www.amazon.com/dp/1594859450/?coliid=I1NRRT8SSFPMIN&colid=2RG67SD0HM3Y4&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Book - Wilderness Navigation",
      description: "",
      price: 15,
      tax: 4,
      imageUrl: require("../assets/product-images/SC_BOOK_WILDERNESS.png"),
      isJunior: false,
      isBoth: false,
      kitId: "SCIENCE"
    },
    {
      url: "https://www.amazon.com/dp/B00AM5XB5O/?coliid=IUCBBO9O62ZP7&colid=2RG67SD0HM3Y4&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Portable LED Monocular Compound Microscope with Dust Cover, 2 Clips and 2 Eyepieces",
      description: "",
      price: 110,
      tax: 10,
      imageUrl: require("../assets/product-images/SC_AMSCOPE.png"),
      isJunior: false,
      isBoth: false,
      kitId: "SCIENCE"
    },
    {
      url: "https://www.amazon.com/dp/B0BK47QT9Z/?coliid=IBLH3PPMO9P65&colid=2RG67SD0HM3Y4&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Handheld Digital Microscope with LED Screen & 800X Magnification",
      description: "",
      price: 62,
      tax: 8,
      imageUrl: require("../assets/product-images/SC_Handheld_Microsope.png"),
      isJunior: false,
      isBoth: true,
      kitId: "SCIENCE"
    },
    {
      url: "https://www.amazon.com/dp/B095XQVTNM/?coliid=I37WIGO5A86TJ&colid=2RG67SD0HM3Y4&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Telescope with 70mm Aperture, Phone Adapter and Wireless Remote",
      description: "",
      price: 100,
      tax: 12,
      imageUrl: require("../assets/product-images/SC_TELESCOPE.png"),
      isJunior: false,
      isBoth: true
    },
    {
      url: "https://www.amazon.com/dp/B0DFWC3KLY/?coliid=I2W63FZ746EY6W&colid=2RG67SD0HM3Y4&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Book - How to Use a Compass",
      description: "",
      price: 15,
      tax: 4,
      imageUrl: require("../assets/product-images/SC_BOOK_USE_COMPASS.png"),
      isJunior: true,
      isBoth: false,
      kitId: "SCIENCE"
    },
    {
      url: "https://www.amazon.com/dp/B07CK8B3R3/?coliid=IA38W1B2HDRX6&colid=2RG67SD0HM3Y4&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Orienteering Compass For Map Reading",
      description: "",
      price: 75,
      tax: 12,
      imageUrl: require("../assets/product-images/SC_COMPASS.png"),
      isJunior: false,
      isBoth: true,
      kitId: "SCIENCE"
    }
  ]
 


  // MATH EXTRA

  static MATH_EXTRA = [
    {
      url: "https://www.amazon.com/dp/1951791053/?coliid=I2TB9MOYY8X4M9&colid=1S2W3ULSVDME0&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Sudoku Large Print Puzzle",
      description: "",
      price: 10,
      tax: 4,
      imageUrl: require("../assets/product-images/MATH_SODOKU.png"),
      isJunior: false,
      isBoth: false,
      kitId: "MATH"
    },

    {
      url: "https://www.amazon.com/dp/B097F49QYH/?coliid=I34J2VB10ODWFQ&colid=1S2W3ULSVDME0&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Brainometry - 34 Pieces Geometric Pattern Recognition Kit",
      description: "",
      price: 13,
      tax: 4,
      imageUrl: require("../assets/product-images/MATH_BRAINOMETRY.png"),
      isJunior: true,
      isBoth: false,
      kitId: "MATH"
    },
    {
      url: "https://www.amazon.com/dp/B000URHPLA/?coliid=I19QLPGM5E8Q77&colid=1S2W3ULSVDME0&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Cash Register - Enhances Numbers & Counting Skills",
      description: "",
      price: 40,
      tax: 10,
      imageUrl: require("../assets/product-images/MATH_CASH_REGISTER.png"),
      isJunior: true,
      isBoth: false,
      kitId: "MATH"
    },
    {
      url: "https://www.amazon.com/dp/B08M457BRR/?coliid=I2Y0QCPUT4LVNF&colid=1S2W3ULSVDME0&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "ADSUMUDI - Math Puzzle Solve Mathematical Operations",
      description: "",
      price: 22,
      tax: 4,
      imageUrl: require("../assets/product-images/MATH_ADSUMUDI_GAME.png"),
      isJunior: true,
      isBoth: false,
      kitId: "MATH"
    },
    {
      url: "https://www.amazon.com/dp/B0C61FCL7Z/?coliid=IED9SKUAQOQP6&colid=1S2W3ULSVDME0&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Math Protractor with Rulers For Geometry",
      description: "",
      price: 15,
      tax: 4,
      imageUrl: require("../assets/product-images/MATH_GEOMETRY_SET.png"),
      isJunior: false,
      isBoth: false,
      kitId: "MATH"
    },
    {
      url: "https://www.amazon.com/dp/B0BMQ9HYTR/?coliid=I38591A0BG2WTY&colid=1S2W3ULSVDME0&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Mechanical Calculator with LCD Display",
      description: "",
      price: 18,
      tax: 5,
      imageUrl: require("../assets/product-images/MATH_MECHANICAL_CALC.png"),
      isJunior: false,
      isBoth: true,
      kitId: "MATH"
    },
    

  ]
  


  // LA EXTRA

  static LA_EXTRA = [
    {
      url: "https://www.amazon.com/dp/1952842239/?coliid=I3MUWKO5XB4KNL&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Cursive Handwriting Workbook",
      description: "",
      price: 6,
      tax: 4,
      imageUrl: require("../assets/product-images/LA_BOOK_CURSIVE.png"),
      isJunior: false,
      isBoth: false,
      kitId: "LA"
    },
    {
      url: "https://www.amazon.com/dp/B09FS72LWV/?coliid=I3BJ2DR1IRB8D0&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Blank Comic Book - Create Your Own Comic Book Sketchbook",
      description: "",
      price: 9,
      tax: 3,
      imageUrl: require("../assets/product-images/LA_CREATE_COMIC_BOOK.png"),
      isJunior: false,
      isBoth: false,
      kitId: "LA"
    },
    {
      url: "https://www.amazon.com/dp/B0D4DNKD8N/?coliid=I3DZI7Z6DQLZS8&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Blank Story Book - Set of Two, with 50 Story Stickers",
      description: "",
      price: 25,
      tax: 4,
      imageUrl: require("../assets/product-images/LA_STORY_ART_KIT.png"),
      isJunior: true,
      isBoth: false,
      kitId: "LA"
    },
    {
      url: "https://www.amazon.com/dp/B095WXRNCM/?coliid=I1Y3H8YQURL4VC&colid=7UCVX5R00Z1X&psc=0&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Drawing Art Kit - Includes Sketching Pencils, Coloring Book and Charcoal Pens for Shading",
      description: "",
      price: 26,
      tax: 5,
      imageUrl: require("../assets/product-images/LA_ART_SUPPLIES.png"),
      isJunior: false,
      isBoth: false,
      kitId: "LA"
    },
    {
      url: "https://www.amazon.com/dp/B0BFDJ8CBT/?coliid=I3H6MXNGCDN2EE&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Drawing Kit - Includes 1 Easel, Drawing Pad, Coloring Book, and Crayons",
      description: "",
      price: 26,
      tax: 6,
      imageUrl: require("../assets/product-images/LA_DRAWING_KIT.png"),
      isJunior: true,
      isBoth: false,
      kitId: "LA"
    },
    {
      url: "https://www.amazon.com/dp/B0B3W4HNKC/?coliid=I22G4NITRYBZL7&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Handwriting Practice Paper - 120 Blank Dotted Lines Papers",
      description: "",
      price: 6,
      tax: 2,
      imageUrl: require("../assets/product-images/LA_HANDWRITING_PAPER.png"),
      isJunior: true,
      isBoth: false,
      kitId: "LA"
    },

    {
      url: "https://www.amazon.com/dp/B07WFB88VP/?coliid=IOPUMKVOLPBTR&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Letter Formation Sand Tray With Wooden Pen",
      description: "",
      price: 19,
      tax: 4,
      imageUrl: require("../assets/product-images/LA_LETTER_SAND.png"),
      isJunior: true,
      isBoth: false,
      kitId: "LA"
    },
    {
      url: "https://www.amazon.com/dp/B0DFGTYPVG/?coliid=I3W8UGTZFTBCJ&colid=7UCVX5R00Z1X&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "USB Chargeable Desk Lamp with Auto Dimming For Reading",
      description: "",
      price: 38,
      tax: 4,
      imageUrl: require("../assets/product-images/LA_LAMP.png"),
      isJunior: false,
      isBoth: true,
      kitId: "LA"
    },
  ]


  // SS EXTRA

  static SS_EXTRA = [
    {
      url: "https://www.amazon.com/dp/B0002TV2NS/?coliid=I3GG4Q845ZQL0W&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "World World 2 Historical Board Game",
      description: "",
      price: 56,
      tax: 8,
      imageUrl: require("../assets/product-images/SS_MEMOIR.png"),
      isJunior: false,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/B08FHZQM6P/?coliid=I34BI4E9GM07QS&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Gem Excavation Kit - Ancient Egyptian Pyramids",
      description: "",
      price: 30,
      tax: 5,
      imageUrl: require("../assets/product-images/SS_EGYPT.png"),
      isJunior: true,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/B0B44KX88C/?coliid=I2WQXDXCX5CV28&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Board Game- Trekking Through History",
      description: "",
      price: 55,
      tax: 8,
      imageUrl: require("../assets/product-images/SS_TREKKING.png"),
      isJunior: false,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/B07W5VMW4G/?coliid=I2XDWDKZFH0FNQ&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "New York City 3D Puzzle Kit",
      description: "",
      price: 25,
      tax: 4,
      imageUrl: require("../assets/product-images/SS_CUBIC_FUN.png"),
      isJunior: true,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/1774260131/?coliid=I30BX2YMJTFOF8&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Book - The Constitution of the United States",
      description: "",
      price: 10,
      tax: 4,
      imageUrl: require("../assets/product-images/SS_CONSTITUTION.png"),
      isJunior: false,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/1637168411/?coliid=I1Z2CSIPNY5ZZ5&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Book - American History For Kids",
      description: "",
      price: 20,
      tax: 4,
      imageUrl: require("../assets/product-images/SS_US_HISTORY.png"),
      isJunior: true,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/B0CFWW9LHR/?coliid=I2CPYK8XZN37MW&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Bill of Rights Posters - Set of 10 (12 x 18 Inch)",
      description: "",
      price: 34,
      tax: 6,
      imageUrl: require("../assets/product-images/SS_BILL_OF_RIGHTS.png"),
      isJunior: false,
      isBoth: true,
      kitId: "SOCIAL_STUDIES"
    },
    {
      url: "https://www.amazon.com/dp/B0BXHLDHNL/?coliid=IPM4TD3D51AYG&colid=12LYHKNW9W61R&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
      title: "Magnetic Acrylic Calendar (15 x 12 Inches) - Set of two",
      description: "",
      price: 20,
      tax: 4,
      imageUrl: require("../assets/product-images/SS_FRIDGE_CALENDAR.png"),
      isJunior: false,
      isBoth: false,
      kitId: "SOCIAL_STUDIES"
    },


  ]

  static getKitEssentials = (
  ) => {
    const elements = [
     // isIPAD ? this.IPAD : this.MACBOOK,
      //this.SPECTRUM_BOOK,
      this.STATIONARY,
      this.CURRICULUM,
    ];

  
    return elements;
  };

  static getKitPriceAndTotals = (elements = [], title = "", includesAppleProduct = false, margin = 0, shipping = 0) => {
    const PROFIT_MARGIN = +margin > 0 ? +margin : 20 // includesAppleProduct || title?.includes('Special Needs') ?  200 : 120;
    const result = {
      price:( _.sum(elements?.map((e) => e?.selectedVariationIndex >= 0 ? +e?.variations?.[e?.selectedVariationIndex]?.price :
      
      e?.variations?.length > 0 && e?.variations?.[0]?.price ? +e?.variations?.[0]?.price
      :
      +e?.price
    )) + PROFIT_MARGIN)?.toFixed(2),
      title: title,
      shipping: shipping > 0 ? +shipping :  +50.00,
      tax: _.sum(elements?.map((e) => +e?.price * 0.09 + +e?.price * 0.0204 ) )?.toFixed(2),
      elements: elements,
    };
    var total = (+result.price + +result.shipping + +result.tax)
    result.total = Number(total).toFixed(2)

    return result;
  };


  static getDbKitDetails = async (
    isIPAD = false,
    isMacbook = false,
    addEarpods = false,
    kitId,
    kit
    ) => {

    const elements = await LearningKitsModel.getKitProducts(kitId)
    this.addAppleProducts(elements, isIPAD, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(elements, "", false, kit?.margin, kit?.shipping);
    if(kit?.shipping > 0) {
      const n = +kit?.shipping
      if(!isNaN(n))
      result.shipping = n
    }
    return result;
  }

  static updateKitPriceWithAddOns = (
    elements = [],
    isIPAD = false,
    isMacbook = false,
    addEarpods = false,
    isMsLaptop = false,
    kit
    ) => {
      let k = {...kit}
      elements  = elements?.filter(f => f?.id !== this.IPAD.id  && f?.id !== this.MACBOOK?.id && f?.id !== this.EARPODS.id && f?.id !== this.MS_SURFACE_LAPTOP.id)
    this.addAppleProducts(elements, isIPAD, isMacbook, addEarpods)
    if(isMsLaptop) {
      elements.push(this.MS_SURFACE_LAPTOP)
    }
    const isAppleProducts = isIPAD || isMacbook
    const result = this.getKitPriceAndTotals(elements, "", isAppleProducts, kit?.margin, kit?.shipping );
    k.details = result;
    return k;
    
  }

  static getCoreKit = (
    isIPAD = false,
    addEarpods = false,
    isJunior = false
  ) => {
    const elements = [
      isIPAD ? this.IPAD : this.MACBOOK,
      this.CORE_SPECTRUM_BOOK,
      isJunior
        ? this.LANGUAGE_ARTS_KIT_AGE_4_12
        : this.LANGUAGE_ARTS_KIT_AGE_13_plus,

      isJunior
        ? this.SOCIAL_STUDIES_KIT_AGE_4_12
        : this.SOCIAL_STUDIES_KIT_AGE_13_plus,

      isJunior ? this.SCIENCE_KIT_AGE_4_12 : this.SCIENCE_KIT_AGE_13_plus,

      isJunior ? this.MATH_KIT_AGE_4_12 : this.MATH_KIT_AGE_13_plus,

      this.STATIONARY,
      this.CURRICULUM,
    ];

    if (addEarpods) {
      elements.push(this.EARPODS);
    }

    const result = this.getKitPriceAndTotals(elements, "Core Kit");
    return result;
  };

  static getMathKit = (
    isIpad = false,
    isMacbook = false,
    addEarpods = false,
    isJunior = false
  ) => {
    const elements =( this.getKitEssentials().concat(
      isJunior ? this.MATH_KIT_AGE_4_12 : this.MATH_KIT_AGE_13_plus,
      isJunior ? this.SPECIAL_NEEDS_MATH____DINOSAUR : this.SPECIAL_NEEDS_MATH____WHITE_BOARD,
      isJunior ? this.SPECIAL_NEEDS_MATH____FUN_FOAM : null,
      this.SPECIAL_NEEDS_MATH____FLOOR_MAT
    ).concat(this.MATH_EXTRA?.filter(f => {
      if(isJunior) {
        return f?.isJunior || f?.isBoth
      }
      return !f?.isJunior || f?.isBoth
    })))?.filter( f => f);
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(elements, "Math Kit");
    return result;
  };

  static getScienceKit = (
    isIpad = false,
    isMacbook = false,
    addEarpods = false,
    isJunior = false
  ) => {
    const elements = (this.getKitEssentials().concat(
      isJunior ? this.SCIENCE_KIT_AGE_4_12 : this.SCIENCE_KIT_AGE_13_plus,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_HERB_GARDEN_KIT,
      isJunior ? this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_INDOOR_GARDEN : null,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_SPACE_EXPLORATION
    ).concat(this.SC_EXTRA?.filter(f => {
      if(isJunior) {
        return f?.isJunior || f?.isBoth
      }
      return !f?.isJunior || f?.isBoth
    })))?.filter(f=>f);
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(elements, "Science Kit");
    return result;
  };

  static getLanguageArtsKit = (
    isIpad = false, isMacbook = false, addEarpods = false, isJunior = false
  ) => {
    const elements = (this.getKitEssentials().concat(
      isJunior
        ? this.LANGUAGE_ARTS_KIT_AGE_4_12
        : this.LANGUAGE_ARTS_KIT_AGE_13_plus,
        isJunior ?  this.SPECIAL_NEEDS_LANGUAGE_ARTS___FIDGET: this.SPECIAL_NEEDS_LANGUAGE_ARTS____AMAZON_KINDLE,
        isJunior ? this.SPECIAL_NEEDS_LANGUAGE_ARTS___BT_SPEAKER : null,
       isJunior ? this.SPECIAL_NEEDS_LANGUAGE_ARTS____ABC_RUG : null
    ).concat(this.LA_EXTRA?.filter(f => {
      if(isJunior) {
        return f?.isJunior || f?.isBoth
      }
      return !f?.isJunior || f?.isBoth
    })))?.filter(f => f);
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(elements, "Math Kit");
    return result;
  };

  static getSocialStudiesKit = (
    isIpad = false, isMacbook = false, addEarpods = false, isJunior = false
  ) => {
    const elements = (this.getKitEssentials().concat(
      isJunior
        ? this.SOCIAL_STUDIES_KIT_AGE_4_12
        : this.SOCIAL_STUDIES_KIT_AGE_13_plus,
        isJunior ? this.SPECIAL_NEEDS__SOCIAL_STUDIES_BREAKING_BARRIERS_GAME : null,
        isJunior? this.SPECIAL_NEEDS__SOCIAL_STUDIES_NATIONAL_GEOGRAPHIC_ARTS_KIT : null,
        isJunior ? this.SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_USA_FLASHCARDS: null

    ).concat(this.SS_EXTRA?.filter(f => {
      if(isJunior) {
        return f?.isJunior || f?.isBoth
      }
      return !f?.isJunior || f?.isBoth
    })))?.filter(f => f);
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(elements, "Math Kit", isIpad || isMacbook || addEarpods);
    return result;
  };

  // SPECIAL NEEDS

  static SPECIAL_NEEDS_MATH____COUNTING_BEARS = {
    url: "https://www.amazon.com/dp/B085ZSMK8P/?coliid=I3SWO82X26T1U7&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "STEM Kit: Number & Color Recognition - 6 Colored Cups, 2 Wooden Dices, 60 Variety Colored Little Bears, 30 Big Bears, and 11 Activity Cards.",
    description: "",
    price: 24,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_COUNTING_BEARS.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____MAGNA_TILES = {
    url: "https://www.amazon.com/dp/B000CBSNKQ/?coliid=I1D3YLYJMUKWOI&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Magnetic Tile Kit - 32 Piece",
    description: "",
    price: 55,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_MAGNA_TILES.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____WHITE_BOARD = {
    url: "https://www.amazon.com/dp/B07RT4LWYY/?coliid=IE7NX15QQQ28V&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Magnetic Dry Erase White Board Double-sided (16 x 12 inches)",
    description: "",
    price: 30,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_WHITE_BOARD.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____VISUAL_TIMER = {
    url: "https://www.amazon.com/dp/B0C3GQW2FP/?coliid=I294MUM04EZ9YW&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "60 Minute Visual Timer",
    description: "",
    price: 25,
    tax: 6,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_VISUAL_TIMER.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____LEGO = {
    url: "https://www.amazon.com/dp/B00NHQF6MG/?coliid=IZCBO635FJMW3&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Brick Box LEGO Set - 790 Pieces",
    description: "",
    price: 50,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_LEGO.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____FLOOR_MAT = {
    url: "https://www.amazon.com/dp/B0B2WTYMVZ/?coliid=I2US8IB4WSB92H&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Thick Washable Rug with Mathematical Education Pattern (60 x 40 inch)",
    description: "",
    price: 42,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_MATT.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____BUSY_BOARD = {
    url: "https://www.amazon.com/dp/B0CQ2XBTSB/?coliid=I189NZIYX45875&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Montessori Busy Board m- Tactile Numbers, Shapes, Hours, and Dates Activities",
    description: "",
    price: 25,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_BUSY_BOARD.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____FUN_FOAM = {
    url: "https://www.amazon.com/dp/B07YSWPBMR/?coliid=I12QO5VSJJSS7I&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Foam Modeling Foam Beads Play Kit",
    description: "",
    price: 32,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_FUN_FOAM.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____DINOSAUR = {
    url: "https://www.amazon.com/dp/B09WN16G4W/?coliid=I3BQDKOTFNUM2G&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Dinosaur Math Balance Montessori Kit - 10 Numbers, Stack of Cards, 20 Dinosaurs, and Balance Scales",
    description: "",
    price: 28,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_DINOSAUR.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____CASH_REGISTER = {
    url: "https://www.amazon.com/dp/B00000DMD2/?coliid=I27898SBTH25PR&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Calculator Cash Register For Early Math Skills",
    description: "",
    price: 30,
    tax: 6,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_CASH_REGISTER.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MATH____CASH_REGISTER = {
    url: "https://www.amazon.com/dp/B08D1SFXRH/?coliid=I89MTFWW7WRQS&colid=2BOC9D5HVHQK5&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Glow Fort Building Kit - 81 Pack Geometric Shape Building STEM Kit",
    description: "",
    price: 38,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MATH_GLOW_FORT.png"),
    isJunior: false,
  };


  static addAppleProducts = (elements = [], isIpad, isMacbook, addEarpods) => {
    if(isIpad) {
      elements.push(this.IPAD)
    }
    if(isMacbook) {
      elements.push(this.MACBOOK)
    }
    if(addEarpods) {
      elements.push(this.EARPODS)
    }

  }

  static getSpecialNeedsMathKit = (isIpad = false, isMacbook = false, addEarpods = false, isJunior = false) => {
    const elements = [
      this.SPECIAL_NEEDS_MATH____BUSY_BOARD,
      this.SPECIAL_NEEDS_MATH____CASH_REGISTER,
      this.SPECIAL_NEEDS_MATH____COUNTING_BEARS,
      this.SPECIAL_NEEDS_MATH____DINOSAUR,
      this.SPECIAL_NEEDS_MATH____FLOOR_MAT,
      this.SPECIAL_NEEDS_MATH____FUN_FOAM,
      this.SPECIAL_NEEDS_MATH____LEGO,
      this.SPECIAL_NEEDS_MATH____MAGNA_TILES,
      this.SPECIAL_NEEDS_MATH____VISUAL_TIMER,
      this.SPECIAL_NEEDS_MATH____WHITE_BOARD,
    ];

    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(elements, "Special Needs - Math", isIpad || isMacbook || addEarpods);
    return result;
  };

  // SPECIAL NEEDS

  static SPECIAL_NEEDS_LANGUAGE_ARTS____SCRABBLE =
    this.LANGUAGE_ARTS_KIT_AGE_4_12;
  static SPECIAL_NEEDS_LANGUAGE_ARTS____ART_SUPPLIES = {
    url: "https://www.amazon.com/dp/B08JCD4PS6/?coliid=IZN44N5KQN2PL&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Wooden Art Set: Crafts Drawing Painting Kit - Drawing Pag, Coloring Book, Oil Pastels, Colored Pencils, Water Color, and 2 Paint Brushes",
    description: "",
    price: 40,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_CRAFT_KIT.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS____AMAZON_KINDLE = {
    url: "https://www.amazon.com/dp/B0CFPJYX7P/?coliid=I2WLUHA40OH4AL&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Amazon Kindle Paperwhite (16 GB)",
    description: "",
    price: 170,
    tax: 30,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_KINDLE.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS____ABC_RUG = {
    url: "https://www.amazon.com/dp/B0C9VVHCF2/?coliid=IDP5U0VJMHHG4&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "ABC Washable Rug For Learning The Alphabet - XL 6 ft",
    description: "",
    price: 70,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_ABC_RUG.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS_LANGUAGE_ARTS___SPELL_KIT = {
    url: "https://www.amazon.com/KMUYSL-Matching-Letter-Spelling-Words/dp/B087M4DGD9/ref=sr_1_1?crid=3DUGV8BHVB0V0&dib=eyJ2IjoiMSJ9.X3Um86k8GzyEr0Ov6_47241U5IXxGBAD0yf4lU-N6cgpaMpVqjP2GHVuKzu2UJY82WWhHrnr6g9KDvHeg-TjMCrZDCims7gFBe95oEuuGDC_iT25QeYbd50lhT3CGru3uq4WomDh7iB4Njf_DIYdlw.10rMIAtxQ95AY8pQJMUqP_6n--noHGpYhiqCV_xRaEk&dib_tag=se&keywords=kmuysl+see+and+spell&qid=1733441929&sprefix=KMUYSL+see+and+%2Caps%2C153&sr=8-1",
    title: "Spelling Learning Kit - 80 Piece CVC Word Builder",
    description: "",
    price: 20,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_SPELL_KIT.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS___SIGHT_WORD = {
    url: "https://www.amazon.com/dp/B08XZGZV2J/?coliid=I1HOH18XVJU066&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Sight Word Kit - Alphabet Word Poppits & Flash Cards",
    description: "",
    price: 20,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_SIGHT_WORD.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS___FIDGET = {
    url: "https://www.amazon.com/dp/B08XZGZV2J/?coliid=I1HOH18XVJU066&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Sight Word Kit - Alphabet Word Poppits & Flash Cards",
    description: "",
    price: 35,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_FIDGET.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS___BT_SPEAKER = {
    url: "https://www.amazon.com/dp/B0CBQGFL9V/?coliid=IHEGXBQD1N1LF&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "YOTO: Bluetooth Story Telling Speaker + Story Cards (Starter Pack)",
    description: "",
    price: 35,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_BT_SPEAKER.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS___BOARD_BOOKS = {
    url: "https://www.amazon.com/dp/9387779262/?coliid=I2YR12N1VO0363&colid=7LUS4LWHR7NY&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Alphabet Learning Book Set - ABC, Numbers, Shapes, Colours, Wild Animals, Farm Animals and Pets, Birds, Fruits, Vegetables and Transport",
    description: "",
    price: 20,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_BOARD_BOOKS.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_LANGUAGE_ARTS___PUPPETS = {
    url: "https://www.amazon.com/Melissa-Doug-Safari-Buddies-Puppets/dp/B07J6TJR2G/ref=sr_1_4?crid=28E9P023Y7UAA&dib=eyJ2IjoiMSJ9.cltGaDklYQqXDiN9K7U3C8VnIWje3XMeDjV-1Ti7ILxS-2__m3Ok27BXqRWlNHUXztTAiN3hfgbWOX86vjZOr3u2EH7er_8cBewSSp_oWOKTaRu-_e-Iyk1mABvN4YMDG6ZtFtBEiciTdL_1k08DS_wixd2sDqqmT7oB8o3EKaqTPP391RwiWLQ4cnYimRwQFVXSaxTbTC9W8tpnz01fYkxJ9QzUXFfPUCCTIwoQrwo.jAl8_DrP-K19nGF68DvZc_YGsTppt9r5E_47RSWavUk&dib_tag=se&keywords=Six+Piece+Animal+Variety+Puppet+Set&qid=1733441715&s=books&sprefix=six+piece+animal+variety+puppet+set%2Cstripbooks%2C133&sr=1-4",
    title: "Six Piece Animal Variety Puppet Set",
    description: "",
    price: 40,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_LA_PUPPETS.png"),
    isJunior: false,
  };

  static getSpecialNeedsLanguageArtsKit = (isIpad = false, isMacbook = false, addEarpods = false, isJunior = false) => {
    const elements = [
      this.SPECIAL_NEEDS_LANGUAGE_ARTS___BOARD_BOOKS,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS___BT_SPEAKER,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS___FIDGET,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS___PUPPETS,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS___SIGHT_WORD,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS___SPELL_KIT,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS____ABC_RUG,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS____AMAZON_KINDLE,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS____ART_SUPPLIES,
      this.SPECIAL_NEEDS_LANGUAGE_ARTS____SCRABBLE,
    ];
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(
      elements,
      "Special Needs - Language Arts",
       isIpad || isMacbook || addEarpods
    );
    return result;
  };


  // SPECIAL NEEDS MUSIC 

  static SPECIAL_NEEDS_MUSIC___ORGAN = {
    url: "https://www.amazon.com/dp/B07FDL3NGT/?coliid=I128FL0I8XICTU&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Compact Music Keyboard with Sheet Music Stand",
    description: "",
    price: 90,
    tax: 16,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_KEYBOARD.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MUSIC___ORGAN = {
    url: "https://www.amazon.com/dp/B07FDL3NGT/?coliid=I128FL0I8XICTU&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Compact Music Keyboard with Sheet Music Stand",
    description: "",
    price: 90,
    tax: 16,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_KEYBOARD.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MUSIC___DRUMS = {
    url: "https://www.amazon.com/dp/B09GM1J6TS/?coliid=I2QRP98NCTFF7Y&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Electric Drum Set with Headphones",
    description: "",
    price: 65,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_DRUMS.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MUSIC___MOZART_CUBE = {
    url: "https://www.amazon.com/dp/B00HQ0WAJG/?coliid=I1015MB5OBHPTO&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Music Speaker With Lights and Calming Music for Sound and Instrument Recognition",
    description: "",
    price: 30,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_MOZART_CUBE.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MUSIC___XYLOPHONE = {
    url: "https://www.amazon.com/dp/B07DKFNSTJ/?coliid=I36M0U4F55N4RO&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Eight Notes Xylophone",
    description: "",
    price: 40,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_XYLOPHONE.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MUSIC___THUMB_PIANO = {
    url: "https://www.amazon.com/dp/B07Q38FWFJ/?coliid=I25LXE69VNXJA0&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Portable Thumb Piano 17 Keys",
    description: "",
    price: 30,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_THUMB_PIANO.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS_MUSIC___STEEL_TONGUE_DRUM = {
    url: "https://www.amazon.com/dp/B0CLSDR32J/?coliid=IW0W74HKPZ8O1&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Sing-A-Long Hand Drum with Story Book",
    description: "",
    price: 50,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_STEEL_TONGUE_DRUM.png"),
    isJunior: false,
  };

  
  static SPECIAL_NEEDS_MUSIC___STEEL_DESK_BELLS = {
    url: "https://www.amazon.com/dp/B07RZTS3D4/?coliid=IP8ZASVQCKWYA&colid=31SI5C086FC2N&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Eight Notes Musical Bells",
    description: "",
    price: 60,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_MUSIC_DESK_BELLS.png"),
    isJunior: false,
  };

  static getSpecialNeedsMusicKit = (isIpad = false, isMacbook = false, addEarpods = false, isJunior = false, isSpecialNeeds = true) => {
    const elements = [
      this.SPECIAL_NEEDS_MUSIC___ORGAN,
      this.SPECIAL_NEEDS_MUSIC___DRUMS,
      this.SPECIAL_NEEDS_MUSIC___MOZART_CUBE,
      this.SPECIAL_NEEDS_MUSIC___XYLOPHONE,
     isSpecialNeeds ?  this.SPECIAL_NEEDS_MUSIC___STEEL_DESK_BELLS : this.MUSIC_EXTRA[0],
      this.SPECIAL_NEEDS_MUSIC___STEEL_TONGUE_DRUM,
      this.SPECIAL_NEEDS_MUSIC___THUMB_PIANO,     
    ];

    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(
      elements,
     isSpecialNeeds  ? "Special Needs - Musical Education" : "Musical Education",
       isIpad || isMacbook || addEarpods
    );
    return result;
  };



  // SPECIAL NEEDS - Social Studies

  static SPECIAL_NEEDS_SOCIAL_STUDIES_FLASH_CARDS = {
    url: "https://www.amazon.com/dp/B079HFFF5Q/?coliid=I299LP3MDI38HC&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Flash Cards- Solar System for Kids, Human Body and Geography",
    description: "",
    price: 15,
    tax: 5,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_SCIENCE_FLASH_CARDS.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_POLYMER_CLAY = {
    url: "https://www.amazon.com/dp/B08QZ69GJM/?coliid=I1G4KRI29EOLH6&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Modeling Clay DIY Starter Kit - Includes Molding Clay and Sculpting Tools",
    description: "",
    price: 28.99,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_CLAY.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_NATIONAL_GEOGRAPHIC_ARTS_KIT = {
    url: "https://www.amazon.com/dp/B0BSKSXJN9/?coliid=IZUV7RK75O6N4&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "National Geographic Mega Arts and Crafts Kit - Makes 19 Unique Pieces of Art, 3 Mosaic Art Pieces, Slow-Dry Plaster, Glass Tiles, Molds, and a Stir Stick",
    description: "Mosaic Kit, Marbling Paint Kit & Air Dry Clay Pottery Kit – Art Projects for Kids Ages 8-12",
    price: 36.67,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_NAT_GEO_MEGA_ARTS.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_SOCIAL_CARD_GAME = {
    url: "https://www.amazon.com/dp/B004QIRNC0/?coliid=I12GI4SB6LMXAH&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "SUSSED: Social Card Game - Helps with Social Development",
    description: "Kids Stocking Stuffer & Family Travel Game - Fun & Easy to Play - All Ages 6 and Up - Hello Yellow Deck",
    price: 16.99,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_SUSSED.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_WHITEBOARD = {
    url: "https://www.amazon.com/dp/B07RT4LWYY/?coliid=I19AAI4582E46E&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Dry Erase White Board (16 x 12 inches)",
    description: "Portable Mini Easel Double Sided on Table Top with Holder for Drawing, Teacher Instruction, Memo Board",
    price: 30,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_WHITE_BOARD.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_FOAM_MODELING_KIT = {
    url: "https://www.amazon.com/dp/B07YSWPBMR/?coliid=ILCV3WRPGZKCQ&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Foam Modeling Kit",
    description: "Children’s Educational Clay for Arts Crafts Kindergarten, Preschool Kids Toys Develop Creativity, Motor Skills",
    price: 32.99,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_FOAM_KIT.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_LITTLE_SPOT_FEELINGS = {
    url: "https://www.amazon.com/dp/1951287665/?coliid=I1EYDMCSC354MN&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Spot of Feelings Book",
    description: "(Book 25-32: Empathy, Frustration, Calm, Belonging, Worry, Boredom, Flexible Thinking, & Feelings Detective)",
    price: 62.23,
    tax: 20,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_SPOT_FEELING.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_USA_MONUMENTS = {
    url: "https://www.amazon.com/dp/B09KCJ9PT4/?coliid=I1NS8UTNXYVDXO&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Monuments of The USA Puzzle Kit",
    description: "STEM Toys & Games | Educational Toys for Kids 5-7 | Birthday Gift for Boys & Girls Age 5 Years & Up",
    price: 32.99,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_MAPOLOGY.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_WORLD_MONUMENTS = {
    url: "https://www.amazon.com/dp/B09WYR5S7Z/?coliid=I14E40XRCQFULP&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Monuments of The World Puzzle Kit",
    description: "Learning & Educational Toys for Kids 5-7 | Puzzles for Kids Ages 4-8",
    price: 32.99,
    tax: 9,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_MAPOLOGY_WORLD.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_BREAKING_BARRIERS_GAME = {
    url: "https://www.amazon.com/dp/B07T5KQ5YT",
    title: "Breaking Barriers - Social Skills Blocks Kit - 208 Thought-Provoking Questions on Emotions, Developing Social Kids",
    description: "A Feelings Game for Kids That Develops Emotion Regulation and Explores Anger, Sadness, Fear and Joy",
    price: 42.00,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_BREAKING_BARRIERS.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_WORLD_MAP_JIGSAW = {
    url: "https://www.amazon.com/dp/B0CHNQ7V5B/?coliid=I3J9NJXJY645I2&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "World Map Round Jigsaw Puzzle",
    description: "Geography Floor Puzzle for Kids, Large 70 Piece Puzzle for Toddler Ages 3-5, Preschool Learning Educational",
    price: 29.99,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_WORLD_MAP.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_VISUAL_SCHEDULE = {
    url: "https://www.amazon.com/dp/B0C4FNDZ4S/?coliid=I2RDZGFEI5H3OG&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Visual Schedule For Learning Days of The Week",
    description: "Bedtime Routine Chart for Toddlers, Double Side Weekly Planner Board with 109 Cards for Home School Planner",
    price: 29.99,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_VISUAL_SCHEDULE.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_USA_FLASHCARDS = {
    url: "https://www.amazon.com/dp/B07ZK4JZB2",
    title: "United States Flash Card Puzzle with Flags and 52 Flash Cards Kit",
    description: "Educational Toys for Kids Ages 5-8 | Learn Geography Puzzles for Kids Ages 8-10",
    price: 37.99,
    tax: 9,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_US_MAP_FLASH_CARDS.png"),
    isJunior: false,
  };
  
  static SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_WORLD_MAP_PUZZLE = {
    url: "https://www.amazon.com/dp/B079SXW3WD/?coliid=I2S91YNCZ6WJPC&colid=1TSWI7504XBA9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "World Map Puzzle",
    description: "Includes Country Flags & Capitals | Fun Jigsaw Puzzle Kids Ages 5-8 | Educational Toys for Ages 8-13",
    price: 30.99,
    tax: 6,
    imageUrl: require("../assets/product-images/special_needs/SN_SL_WORLD_MAP_PUZZLE.png"),
    isJunior: false,
  };
  

  static getSpecialNeedsSocialStudiesKit = (isIpad = false, isMacbook = false, addEarpods = false, isJunior = false) => {
    const elements = [
      this.SPECIAL_NEEDS_SOCIAL_STUDIES_FLASH_CARDS,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_BREAKING_BARRIERS_GAME,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_FOAM_MODELING_KIT,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_LITTLE_SPOT_FEELINGS,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_USA_FLASHCARDS,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_USA_MONUMENTS,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_WORLD_MAP_PUZZLE,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_MAPOLOGY_WORLD_MONUMENTS,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_NATIONAL_GEOGRAPHIC_ARTS_KIT,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_POLYMER_CLAY,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_SOCIAL_CARD_GAME,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_VISUAL_SCHEDULE,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_WHITEBOARD,
      this.SPECIAL_NEEDS__SOCIAL_STUDIES_WORLD_MAP_JIGSAW
    ];
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(
      elements,
      "Special Needs - Social Studies",
       isIpad || isMacbook || addEarpods
    );
    return result;
  };

  // SPECIAL NEEDS -  SCIENCE

  static SPECIAL_NEEDS__SCIENCE_MAGNIFYING_BUG = {
    url: "https://www.amazon.com/dp/B096V12QJY/?coliid=I1T9B4UALKJUV9&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Magnifying Insect Box",
    description: "",
    price: 19.99,
    tax: 6,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_MAGNIFYING_BUG.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_HERB_GARDEN_KIT = {
    url: "https://www.amazon.com/dp/B08KRPB8JM/?coliid=IG2DPYVXX7RCW&colid=EXISAF2CUG1K&psc=0&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Herb Garden Kit - 8 Herb Seed Packs, Soil Pellets, Plant Markets and Reusable Pots and Trays and Grow Guide.",
    description: "",
    price: 60.99,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_HERB_GARDEN_KIT.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_SPACE_EXPLORATION = {
    url: "https://www.amazon.com/dp/B0BG1TFJHC/?coliid=IML2IXZYW6DAU&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "STEM Kit - Aerospace Building LEGOS (566 Pieces)",
    description: "",
    price: 30.99,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_SPACE_EXPLORATION.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_MAGNETIC_POLES = {
    url: "https://www.amazon.com/dp/B007WDGZLQ/?coliid=I10SXMEM260PLO&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "STEM Kit - Earth's Magnetic Poles - Includes Variety Magnet Set with Activities",
    description: "",
    price: 30.99,
    tax: 6,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_MAGNETIC_POLES.png"),
    isJunior: false,
  };

  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_FOAM_MODELING = {
    url: "https://www.amazon.com/dp/B07YSWPBMR/?coliid=I2W5D1TWZM0QZW&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Foam Modeling Kit",
    description: "",
    price: 28.99,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_FOAM_MODELING.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_NAT_EGO_CHEM = {
    url: "",
    title: "Chemistry Kit - Volcano & Rocket Launching - Includes Baking Soda, Citric Acid, Calcium Chloride with Tubes, Beakers, and Gloves",
    description: "",
    price: 36.35,
    tax: 6,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_NAT_EGO_CHEM.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_INDOOR_GARDEN = {
    url: "https://www.amazon.com/dp/B0CTRTJBC3/?coliid=I9S58EIPGG90C&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Indoor Garden Hydroponic Kit - LED Grow Light System, Power Adapter, Grow Sponges, and Lettuce Seeds",
    description: "",
    price: 56.99,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_INDOOR_GARDEN.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_PERIOD_TABLE_FLASH_CARDS = {
    url: "https://www.amazon.com/dp/B0B1M7SF3N/?coliid=IJYIITPI7QIP8&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Chemistry - Periodic Table Flash Cards",
    description: "",
    price: 20.12,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_PERIOD_TABLE_FLASH_CARDS.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_FIRST_100_WORDS = {
    url: "https://www.amazon.com/dp/1728211247/?coliid=I38N3Z8BYJKLL4&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Science Book - My first 100 Science Words",
    description: "",
    price: 12.99,
    tax: 5,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_FIRST_100_WORDS.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_SPACE_RUG = {
    url: "https://www.amazon.com/dp/B0BNVNDBMY/?coliid=I24PIVJVYF27M&colid=EXISAF2CUG1K&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Space Rug - Solar System (2 x 3 Feet)",
    description: "",
    price: 29.23,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_SCIENCE_SPACE_RUG.png"),
    isJunior: false,
  };



  static getSpecialNeedsScienceKit = (isIpad = false, isMacbook = false, addEarpods = false, isJunior = false) => {
    const elements = [
      this.SPECIAL_NEEDS__SCIENCE_MAGNIFYING_BUG,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_FIRST_100_WORDS,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_FOAM_MODELING,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_HERB_GARDEN_KIT,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_INDOOR_GARDEN,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_MAGNETIC_POLES,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_NAT_EGO_CHEM,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_PERIOD_TABLE_FLASH_CARDS,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_SPACE_EXPLORATION,
      this.SPECIAL_NEEDS__SCIENCE_SN_SCIENCE_SPACE_RUG
    ];
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(
      elements,
      "Special Needs - Science",
      isIpad || isMacbook || addEarpods
    );
    return result;
  };



  // SPECIAL NEEDS PE 
  static SPECIAL_NEEDS__PE_NINA_WARRIOR = {
    url: "https://www.amazon.com/dp/B07DQQQY6G/?coliid=IB49RX2BM8JDZ&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Outdoors Racing Course - With a Hop Sack, Jump Rope, and Agility Rings",
    description: "",
    price: 75,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_NINJA_WARRIOR.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_RING_TOSS = {
    url: "https://www.amazon.com/dp/B0BQBMXRT8/?coliid=I20ZZMJ6PQ2VSZ&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Ring Toss Outdoor Activity Kit",
    description: "",
    price: 36,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_RING_TOSS.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_ART3D = {
    url: "https://www.amazon.com/dp/B075B71KD3/?coliid=IDIYAWHH3R8OH&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Liquid Fusion Activity Play Kit - Step & Blend Color Tiles",
    description: "",
    price: 120,
    tax: 25,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_ART3D.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_PUZZLE_FLOOR = {
    url: "https://www.amazon.com/dp/B00DQEKHUA/?coliid=I2LMNIP87DJPSQ&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Alphabet Foam Puzzle Floor Mat",
    description: "",
    price: 45,
    tax: 12,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_PUZZLE_FLOOR.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_count_rug = {
    url: "https://www.amazon.com/dp/B07X131H3P/?coliid=I29N7LRH99UTYF&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Hop & Count Rug - Skid-Proof Hopscotch Rug (5 x 2 Feet)",
    description: "",
    price: 35,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_HOP_COUNT_RUG.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_OBSTACLE_COURSE = {
    url: "https://www.amazon.com/dp/B07S6XWLJC/?coliid=I2U8HY64SJ48U&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Backyard Obstacle Course - For Building Coordination and Enhancing Physical Activity",
    description: "",
    price: 80,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_OBSTACLE_COURSE.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_CORNHOLE = {
    url: "https://www.amazon.com/dp/B07KF9Q47V/?coliid=I3GXJ0BDJJ3P6V&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Portable Collapsible 5 Holes Cornhole Set with 8 Bean Bags",
    description: "",
    price: 60,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_CORNHOLE.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_MUSIC_BOXING = {
    url: "https://www.amazon.com/dp/B0CXXXZTBL/?coliid=I1WA0ZXNER1CJ3&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Music Boxing Machine with Two Pairs of Gloves",
    description: "",
    price: 140,
    tax: 10,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_MUSIC_BOXING.png"),
    isJunior: false,
  };
  static SPECIAL_NEEDS__PE_PEANUT_BOUCE = {
    url: "https://www.amazon.com/dp/B01C45GL88/?coliid=I2UW340TUHHJ68&colid=2W5K5H8B9D5H9&psc=1&ref_=list_c_wl_lv_vv_lig_dp_it",
    title: "Peanut Shaped Bounce Ball For Exercise & Balance Training",
    description: "",
    price: 25,
    tax: 8,
    imageUrl: require("../assets/product-images/special_needs/SN_PE_PEANUT_BOUCE.png"),
    isJunior: false,
  };
 
  static SPECIAL_NEEDS__PE_SOCCER_SET = {
    url: "https://www.amazon.com/dp/B0DGGP55H6/ref=sspa_dk_detail_3?psc=1&pd_rd_i=B0DGGP55H6&pd_rd_w=D8hWw&content-id=amzn1.sym.386c274b-4bfe-4421-9052-a1a56db557ab&pf_rd_p=386c274b-4bfe-4421-9052-a1a56db557ab&pf_rd_r=A576FY5VRDB396C5JZDA&pd_rd_wg=EifQC&pd_rd_r=69abc9b5-6302-4707-8624-8394846c0a01&s=sporting-goods&sp_csd=d2lkZ2V0TmFtZT1zcF9kZXRhaWxfdGhlbWF0aWM",
    title: "Soccer Kit - Includes 2 Nets, 1 Soccer Ball, and 1 Agility Ladder",
    description: "",
    price: 55,
    tax: 10,
    imageUrl: require("../assets/product-images/PE_SOCCER.png"),
    isJunior: false,
  };
 

  static getSpecialNeedsPEKit = (isIpad = false, isMacbook = false, addEarpods = false, isJunior = false, isSpecialNeeds = true) => {
    const elements = [
   isSpecialNeeds ?  this.SPECIAL_NEEDS__PE_ART3D : null,
    this.SPECIAL_NEEDS__PE_CORNHOLE,
    this.SPECIAL_NEEDS__PE_MUSIC_BOXING,
   isJunior || isSpecialNeeds ?  this.SPECIAL_NEEDS__PE_PUZZLE_FLOOR : null,
    this.SPECIAL_NEEDS__PE_NINA_WARRIOR,
    isJunior || isSpecialNeeds ?   this.SPECIAL_NEEDS__PE_OBSTACLE_COURSE : null,
    this.SPECIAL_NEEDS__PE_PEANUT_BOUCE,
    this.SPECIAL_NEEDS__PE_RING_TOSS,
    isJunior || isSpecialNeeds ?   this.SPECIAL_NEEDS__PE_count_rug : null,
    this.SPECIAL_NEEDS__PE_SOCCER_SET
    ]?.filter( f=> f);
    this.addAppleProducts(elements, isIpad, isMacbook, addEarpods)
    const result = this.getKitPriceAndTotals(
      elements,
      "Special Needs - Physical Education",
     isIpad || isMacbook || addEarpods
    );
    return result;

  }


  static MUSIC_EXTRA = [
    {
      url: "https://www.amazon.com/Best-Choice-Products-Instrument-Amplifier/dp/B005M4X1HI/ref=sr_1_15?crid=1NPRVHXIO24AE&dib=eyJ2IjoiMSJ9.umzuwoUqmyjlWSDDXWiwoErVNVsmOzsu3dUYz8er30weyZ5CV-CBzaIUg_Bg5u6NsFT_ZMfbEmZDpCioB9G0rtjliT81_E_wHll5JOPOYYOuXr9sRtUqraLiiEfhHdP6J2uD1trwMCTgAeywqvicX-zDB7-EDiaAvMKCJqvPnpLFdWS26osK3rjoVEnytcVo6UgyL5-Gr8tU1yaM8cIQDG5JN4-2vNt9eOCMyLOhd-GmEoO5KNDMSKTIZT6oChzicbbs9LCuJKeJ6noiVIBmXlHc9Twkj-2fNHu3uZKKeRM.j1Q-YmhofNtiqaBB-C60eUs5VNzLbEDvrn5Xq7O_Yv8&dib_tag=se&keywords=junior%2Bguitar&qid=1733542232&sprefix=junior%2Bguita%2Caps%2C189&sr=8-15&th=1",
      title: "Electric Guitar Starter Kit - Includes Guitar, Amplifier and Bag.",
      description: "",
      price: 120,
      tax: 10,
      imageUrl: require("../assets/product-images/MUSIC_GUITAR.png"),
      isJunior: false,
      isBoth: true
    }
  ]

  static getSpecialNeedsKits = (isIpad = false, isMacbook = false, isAirPods = false, isJunior = false) => {
    return []
    const kits = [
      {
        imgUrl: require("../assets/images/Math_kit.png"),
        title: "Special Needs - Math",
        description:
          "Comprehensive and fun kit for tackling math skills for students with special needs",
        id: "SN_MATH_INTERNAL",
        details: this.getSpecialNeedsMathKit(isIpad, isMacbook, isAirPods, isJunior),
        subject: 'Math',
        isSpecialNeeds: true
      },

      {
        imgUrl: require("../assets/images/Language_arts_kit.png"),
        title: "Special Needs - Language Arts",
        description:
          "Make teaching your special need kids fun and engaging with a set of proven learning tools.",
        id: "SN_LANGUAGE_ARTS_INTERNAL",
        details: this.getSpecialNeedsLanguageArtsKit(isIpad, isMacbook, isAirPods, isJunior),
        subject: 'Language Arts',
        isSpecialNeeds: true
      },

      {
        imgUrl: require("../assets/images/Music_kit.png"),
        title: "Special Needs - Music Education",
        description:
          "This kit includes fun, interactive musical tools to help your loved one's creativity and enhance their learning skills.",
        id: "SN_MUSIC_INTERNAL",
        details: this.getSpecialNeedsMusicKit(isIpad, isMacbook, isAirPods, isJunior),
        subject: 'Music Education',
        isSpecialNeeds: true
      },

      {
        imgUrl: require("../assets/images/Social_studies_kit.png"),
        title: "Special Needs - Social Studies",
        description:
          "Watch your loved one learn about the US and world through fun puzzles, flashcards, and DIY kits.",
        id: "SN_SOCIAL_STUDIES_INTERNAL",
        details: this.getSpecialNeedsSocialStudiesKit(isIpad, isMacbook, isAirPods, isJunior),
        subject: 'Social Studies',
        isSpecialNeeds: true
      },

      {
        imgUrl: require("../assets/images/science_kit.png"),
        title: "Special Needs - Science",
        description:
          "From chemical experiments, to indoor gardening and more. This kit is designed to give your loved one fun and provoke their thinking while learning about about science.",
        id: "SN_SCIENCE_INTERNAL",
        details: this.getSpecialNeedsScienceKit(isIpad, isMacbook, isAirPods, isJunior),
        subject: 'Science',
        isSpecialNeeds: true
      },

      {
        imgUrl: require("../assets/images/PE_kit.jpg"),
        title: "Special Needs - Physical Education",
        description:
          "Engage your student with fun exercises, activities, and obstacle course while enhancing their physical health with this kit.",
        id: "SN_PE_INTERNAL",
        details: this.getSpecialNeedsPEKit(isIpad, isMacbook, isAirPods, isJunior),
        subject: 'PE',
        isSpecialNeeds: true
      },
    ];
    return kits;
  };

  static getLearningKits = (
    isIpad = false,
    isMacbook = false,
    addEarpods = false,
    isJunior = false
  ) => {
    const kits = [
      // {
      //   imgUrl: require("../assets/images/Core_kit.png"),
      //   title: "Core",
      //   description:
      //     "Covers all the required subjects by ESA including Social Studies, Language Arts, Science and Math.",
      //   isCore: true,
      //   details: this.getCoreKit(isIpad, addEarpods, isJunior),
      //   id: "CORE",
      // },

      {
        imgUrl: require("../assets/images/Math_kit.png"),
        title: "Math",
        description:
          "Includes essentials tools to conquer math in an easy to grasp way.",
        id: "MATH",
        details: this.getMathKit(isIpad, isMacbook, addEarpods, isJunior),
      },
      {
        imgUrl: require("../assets/images/Language_arts_kit.png"),
        title: "Language Arts",
        description:
          "From reading to writing and grammar, this kit includes essential and fun elements to help students with their language skills.",
        id: "LANGUAGE_ARTS",
        details: this.getLanguageArtsKit(isIpad, isMacbook, addEarpods, isJunior),
      },
      {
        imgUrl: require("../assets/images/science_kit.png"),
        title: "Science",
        description:
          "Helps students with their science learning through essential tools and interactive kits.",
        id: "SCIENCE",
        details: this.getScienceKit(isIpad, isMacbook, addEarpods, isJunior),
      },
      {
        imgUrl: require("../assets/images/Social_studies_kit.png"),
        title: "Social Studies",
        description:
          "Adds fun, interactivity to learning social studies for students of all grades.",
        id: "SOCIAL STUDIES",
        details: this.getSocialStudiesKit(isIpad, isMacbook, addEarpods, isJunior),
      },
      {
        imgUrl: require("../assets/images/PE_kit.jpg"),
        title: "Physical Education",
        description:
          "Engage your student with fun exercises, activities, and obstacle course while enhancing their physical health with this kit.",
        id: "PE",
        details: this.getSpecialNeedsPEKit(isIpad, isMacbook, addEarpods, isJunior, false),
      },
      {
        imgUrl: require("../assets/images/Music_kit.png"),
        title: "Music Education",
        description:
          "This kit includes fun, interactive musical tools to help your loved one's creativity and enhance their learning skills.",
        id: "MUSIC",
        details: this.getSpecialNeedsMusicKit(isIpad, isMacbook, addEarpods, isJunior, false),
      },
    ];
    return kits;
  };

  static generateUniqueInvoiceNumber = (referral_code = 'NLP') => {
    const timestamp = Date.now().toString(); // Current timestamp in milliseconds
    const randomString = Math.random()
      .toString(36)
      .substring(2, 10)
      .toUpperCase(); // Random alphanumeric string
    let couponCode = "";

    // Sprinkle the timestamp across the random string
    for (let i = 0; i < randomString.length; i++) {
      couponCode += randomString[i] + (timestamp[i] || ""); // Interweave timestamp characters
    }

    return `${referral_code}-${couponCode}`;
  };
}
