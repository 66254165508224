import React, { useState } from "react";

import { FormInput } from "./components/formInput";
import { ScreenContainer } from "./App";
import { FormButton, showMagniAlert } from "./components/formButton";
import alertify from "alertifyjs";
import { callEmailSvc } from "./utils/vGenerator";
import { validateEmail } from "./NewCurriculum";
import { customOrderTemplate } from "./emailTemplates/customOrderTemplate";

const MAX_ALLOWED = 10;
export const CustomOrder = () => {
  const [links, setLinks] = React.useState([""]);
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isDone, setIsDone] = React.useState(false);
  const [zipCode, setZipCode] = React.useState('')

  const onSubmit = async () => {
    setLoading(true);
    callEmailSvc(
      "support@newlessonplan.com",
      "support@newlessonplan.com",
      "Custom Kit Request",
      customOrderTemplate(email, links?.join(" , "), zipCode)
    );
    await delay(1000); // Wait for 1000ms

    setLoading(false);
    setIsDone(true);
  };

  const handleAddInput = () => {
    setLinks([...links, ""]); // Add a new empty input field
  };

  const handleTextChange = (index, text) => {
    const updatedLinks = [...links];
    updatedLinks[index] = text; // Update the specific link in the array
    setLinks(updatedLinks);
  };

  const renderForm = () => {
    return (
      <div className="p-3 md:p-10">

<div className="text-lg text-gray-600 space-y-4 bg-gray-50 p-3 text-center align-items-center">
  <div className="d-flex m-2 ">
<div className="bi bi-bag-check-fill fs-3 text-pink-500 mr-2"></div>
  <div>
  If you're ordering outside Amazon or non-ESA vendors, we’ll create a custom kit for you.


  </div>
  
  </div>
  <div className="bg-green-100 text-green-700 p-3 rounded-md border border-green-300 text-center">
    <strong>Note:</strong> You may mix and match items from different vendors (e.g., some links from Target, others from Etsy, etc.).
  </div>
</div>

        <div className="text-gray-500 mb-10 mt-3"></div>
        <div className=" md:p-10 sm:p-1 border-1 rounded">
          <div>
            {links.map((link, index) => (
              <div className="align-items-center d-flex m-4 ">
                <div className=" rounded-[30px] text-indigo-500">
                  {index + 1}
                </div>
                <div className="  w-2 h-2 bg-indigo-500 rounded-[30px] mx-2"></div>
                <div className="w-100">
                  <FormInput
                    autoFocus
                    className="m-0"
                    key={index}
                    text={link}
                    onTextChange={(text) => handleTextChange(index, text)}
                    placeholder="Enter product link"
                    // label={`Amazon Product Link ${links?.length > 1 ? index + 1 : ""}`}
                  />
                </div>
              </div>
            ))}
            {links?.length >= MAX_ALLOWED ? null : (
              <div className="align-items-center text-center">
                {loading ? null : (
                  <button onClick={handleAddInput} className="text-indigo-500">
                    + Add Another Link
                  </button>
                )}
              </div>
            )}

            <div className="m-10" />

            <FormInput
              text={email}
              onTextChange={(t) => {
                setEmail(t);
              }}
              label="Enter your email (to be notified when the custom kit is ready for review)"
            />

              <FormInput
              text={zipCode} type="number"
              onTextChange={(t) => {
                setZipCode(t);
              }}
              label="Enter zipcode (to calculate accurate shipping)"
            />

            <FormButton
              label="Submit"
              isFullWidth
              isPrimary
              superExtraClass=" bg-pink-500"
              hoverColor="bg-pink-500"
              loading={loading}
              onClick={() => {
                if (
                  !email?.length ||
                  !links?.filter((f) => f?.length)?.length ||
                  !validateEmail(email)
                ) {
                  alertify.alert(
                    "Missing Information",
                    "Please fill in your email and requested product link before submitting"
                  );
                  return;
                }
                if(zipCode?.length !== 5) {
                  alertify.alert(
                    "Missing Zipcode",
                    "Please enter your zipcode to calculate accurate shipping."
                  );
                  return;
                }
                showMagniAlert(
                  () => {
                    onSubmit();
                  },
                  "All set?",
                  "Press ok if you double checked your item links."
                );
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div
        className="p-4 m-5 border rounded shadow-sm mx-auto"
        style={{ maxWidth: "600px" }}
      >
        {/* Success Icon */}
        <div className="text-center mb-3">
          <i
            className="bi bi-check-circle-fill text-success"
            style={{ fontSize: "3rem" }}
          ></i>
        </div>

        {/* Header */}
        <h2 className="text-center text-dark fw-bold ">
          Custom Kit Request Received!
        </h2>

        {/* Body */}
        <p className="text-secondary mb-3 mt-3">
          Thank you for submitting your custom kit request! Here’s what happens
          next:
        </p>
        <ul className="list-group list-group-flush  space-y-5 mb-3 py-3 text-left w-100 mx-auto">
          <li className="">
            <i className="bi bi-link-45deg text-success me-2"></i>
            We’ll check the provided links and vendor details.
          </li>
          <li className="">
            <i className="bi bi-card-checklist text-success me-2"></i>
            Send you a link to review your custom kit & generate invoice.
          </li>

          <li className="">
            <i className="bi bi-box text-success me-2"></i>
            Once approved, we’ll ship your order directly to your doorstep!
          </li>
        </ul>
        <p className="text-secondary"></p>

        {/* Footer */}
        <div className="text-center text-muted mt-4">
          <small>
            If you have any questions, feel free to contact us at{" "}
            <span className="text-indigo-500">support@newlessonplan.com</span>
          </small>
        </div>
      </div>
    );
  };

  return (
    <div>
      <ScreenContainer
        onBack={() => (window.location.href = "/")}
        title={"Create Your Custom Kit"}
        bgColor={"bg-pink-500"}
      >
        {isDone ? renderSuccess() : renderForm()}
      </ScreenContainer>
    </div>
  );
};

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
