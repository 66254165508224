import React from 'react'

export const ApprovalGuarantee = () => {
return(
    <div className="p-20 w-100 fs-5">
        <h1 className='h1 mb-10'>Approval Guarantee </h1>
        
        <p>
            We guarantee ESA approval for all pre-made and custom kits purchased on our website. Each order comes with a carefully crafted, ESA-compliant curriculum designed to meet ESA funding requirements. If your purchase is ever rejected by ESA, we’ll issue you a **full refund—no questions asked**.
        </p>

      

        <h2 className='h2 mt-10 mb-4'>Our Guarantee</h2>
        <p>
            We stand by our approval process. If, for any reason, ESA rejects your purchase, just provide us with the rejection details, and will dispute the rejection with ESA to get you approved and provide them with the necessary documentation. If they still reject, we’ll **refund your money in full**.
        </p>



      
    </div>
)

}
