import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getCurrentPath = () => {
    return window.location.href.split(window.location.origin)[1] || "/";
  };

  const isActive = (path) => getCurrentPath() === path 

  const itemStyle =
    "block py-2 px-4 text-gray-700 hover:text-white hover:bg-gradient-to-r from-[#FF5E8E] to-[#9089fc] cursor-pointer rounded transition-all duration-300";

  const activeStyle =
    "font-bold bg-gradient-to-tr from-[#FF5E8E] to-[#9089fc] text-white";

  return (
    <nav className="bg-white fixed top-0 left-0 w-full z-50  bg-gradient-to-tr ">
      <div className=" mx-auto flex justify-between items-center p-4">
        {/* Logo */}
        <div
          className="cursor-pointer flex items-center"
          onClick={() => (window.location.href = "/")}
        >
          <img
            src={require("../assets/images/web_logo.png")}
            style={{ maxWidth: "60%" }}
            alt="Logo"
          />
        </div>

        {/* Menu Button (Mobile) */}
        <button
          className="lg:hidden text-gray-700 focus:outline-none"
          onClick={toggleMenu}
        >
          {isOpen ? (
            <div className="bi bi-x fs-2 mx-4 "></div>
          ) : (
            <div className="bi bi-list fs-1"></div>
          )}
        </button>

        {/* Drawer Navigation */}
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transform ${
            isOpen ? "translate-x-0 " : "translate-x-full"
          } transition-transform duration-300 ease-in-out z-9999`}
        >
          <div className="p-4 flex justify-between items-center border-b">
            <h3 className="text-lg font-semibold text-gray-800">Menu</h3>
            <button
              className="text-gray-700 focus:outline-none"
              onClick={toggleMenu}
            >
              <div className="bi bi-x fs-3"></div>
            </button>
          </div>
          <div className="mt-4 space-y-2">
            <a href="/" className={`${itemStyle} `}>
              Home
            </a>
            <a
              href="/custom-order"
              className={`${itemStyle} ${
                isActive("/") || isActive("/custom-order") ? activeStyle : ""
              }`}
            >
              Create Order
            </a>
            <a
              href="/build-my-kit"
              className={`${itemStyle} ${isActive("/build-my-kit") && activeStyle}`}
            >
              Order Amazon
            </a>
            <a href="/new" className={`${itemStyle} ${isActive("/new") && activeStyle}`}>
              Create Curriculum
            </a>
            <a
              href="/learning-kits"
              className={`${itemStyle} ${
                isActive("/learning-kits") && activeStyle
              }`}
            >
              Learning Kits
            </a>
            {/* <a
            href="/vouchers-purchase"
            className={`${itemStyle} ${
              isActive("/vouchers-purchase") && activeStyle
            }`}
          >
            Vouchers
          </a> */}
            <a href="/help" className={`${itemStyle} ${isActive("/help") && activeStyle}`}>
              Help
            </a>
          </div>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex space-x-6">
          <a href="/" className={`${itemStyle} `}>
            Home
          </a>
          <a
            href="/custom-order"
            className={`${itemStyle} ${
              isActive("/") || isActive("/custom-order") ? activeStyle : ""
            }`}
          >
            Create Order
          </a>
          <a
            href="/build-my-kit"
            className={`${itemStyle} ${isActive("/build-my-kit") && activeStyle}`}
          >
            Order Amazon
          </a>
          <a href="/new" className={`${itemStyle} ${isActive("/new") && activeStyle}`}>
            Create Curriculum
          </a>
          <a
            href="/learning-kits"
            className={`${itemStyle} ${
              isActive("/learning-kits") && activeStyle
            }`}
          >
            Learning Kits
          </a>
          {/* <a
            href="/vouchers-purchase"
            className={`${itemStyle} ${
              isActive("/vouchers-purchase") && activeStyle
            }`}
          >
            Vouchers
          </a> */}
          <a href="/help" className={`${itemStyle} ${isActive("/help") && activeStyle}`}>
            Help
          </a>
        </div>
      </div>

      {/* Overlay (for closing drawer when clicking outside) */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-30"
          onClick={toggleMenu}
        ></div>
      )}
    </nav>
  );
};

export default Navbar;
